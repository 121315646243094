import React, { AllHTMLAttributes, ReactNode } from 'react'
import GatsbyLink from 'gatsby-link'
import cn from 'classnames'

import { getTextStyles } from '@cmp'
import { GetTextStylesProps } from '../Text'

type NativeProps = AllHTMLAttributes<HTMLAnchorElement>

export interface LinkProps {
  children?: ReactNode
  to?: string
  noStyle?: boolean
  className?: string
  noBorder?: boolean
  self?: boolean
  onClick?: NativeProps['onClick']
  textStyles?: GetTextStylesProps
  onMouseEnter?: NativeProps['onMouseEnter']
  onMouseLeave?: NativeProps['onMouseLeave']
}
export const Link = React.forwardRef<any, LinkProps>(
  ({ children, to, textStyles = {}, className, noBorder = false, noStyle, onClick, self, ...props }, ref) => {
    const classnames = cn(className, {
      [`link ${getTextStyles(textStyles)}`]: !noStyle,
      [`${getTextStyles(textStyles)}`]: noBorder,
    })
    if (!to) {
      return (
        <span onClick={onClick} className={classnames} ref={ref} {...props}>
          {children}
        </span>
      )
    }
    const internal = /^\/(?!\/)/.test(to)

    return internal ? (
      <GatsbyLink onClick={onClick} to={to} className={classnames} ref={ref} {...props}>
        {children}
      </GatsbyLink>
    ) : (
      <a
        onClick={onClick}
        href={to}
        target={!self ? '_blank' : '_self'}
        rel='noopener'
        className={classnames}
        ref={ref}
        {...props}
      >
        {children}
      </a>
    )
  },
)

export default Link
