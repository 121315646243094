import React from 'react'
import { Button, Text } from '@cmp'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import { objectToPath } from '@src/utils/objectToPath'

export interface RelatedContentProps {
  title?: string | null
  className?: string
  linkedPages?: any[] | null
}

export const RelatedContent = ({ title, className = '', linkedPages }: RelatedContentProps) => {
  if (!linkedPages?.length) {
    return null
  }

  return (
    <div className={cn('border-t', className)}>
      <Text as='h3' variant='h3' weight='heavy' className='pt-6'>
        {title}
      </Text>
      <div className='flex flex-wrap -mx-2.5'>
        {linkedPages?.map((page, idx) => {
          return (
            <div key={idx} className='block px-2.5 w-full lg:w-6/12 2xl:w-3/12 py-8'>
              <GatsbyImage image={page.image.gatsbyImageData} alt={page?.title ?? ''} className='min-w-min' />
              <Text className='py-8' as='h3' variant='h3'>
                {page?.title}
              </Text>
              <Button className='w-4/6' href={objectToPath(page.page)} variant='ghost' icon>
                {page.linkText}
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RelatedContent
