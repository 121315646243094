import React, { ReactNode } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES, BLOCKS, MARKS, Hyperlink, Paragraph, ListItem } from '@contentful/rich-text-types'
import { Link, Text, getTextStyles, GetTextStylesProps, Video } from '@cmp'
import { isDefined } from '@src/utils/helpers'
import cn from 'classnames'

type ReactNodeCustom = ReactNode & { customClassNames?: string }

export interface RichTextProps {
  document?: {
    raw?: string | null
    references?: Array<ContentfulRichTextGatsbyReference | null | undefined> | null
  }
  className?: string
  textStyles?: GetTextStylesProps
  customClassNames?: Record<string, string>
  locale?: string
  translate?: (word: string) => string
}

const options = (
  locale: string,
  translate: (word: string) => string,
  textStyles: GetTextStylesProps,
  customClassNames?: Record<string, string>,
) => ({
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => (
      <Text as='span' weight='bold'>
        {text}
      </Text>
    ),
    [MARKS.ITALIC]: (text: ReactNode) => (
      <Text as='span' className='italic'>
        {text}
      </Text>
    ),
    [MARKS.UNDERLINE]: (text: ReactNode) => (
      <Text as='span' className='underline'>
        {text}
      </Text>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node: Hyperlink, children: ReactNode) => {
      if (node.data.uri.includes('youtube.com/embed')) {
        return (
          <div className='h-0 pb-[56.25%] relative'>
            <Video
              className='absolute top-0 left-0 w-full h-full'
              key={node.data.uri}
              videoSrcURL={node.data.uri}
              autoplay
              muted
              controls
              volume={0}
              locale={locale}
              translate={translate}
            />
          </div>
        )
      }
      return (
        <Link className='mb-8' to={node.data.uri} textStyles={{ weight: 'light' }}>
          {children}
        </Link>
      )
    },
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => {
      return (
        <Text
          className='pb-8'
          variant={textStyles?.variant ? textStyles.variant : 'p'}
          weight={textStyles?.weight ? textStyles.weight : 'normal'}
        >
          {children}
        </Text>
      )
    },
    [BLOCKS.HEADING_2]: (_node: ReactNode, children: ReactNode) => (
      <Text as='h2' variant='h2' weight='heavy' className='pt-4 pb-8'>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (_node: ReactNodeCustom, children: ReactNode) => {
      return (
        <Text as='h3' variant='h3' weight='heavy' className={cn('pt-4 pb-8', _node?.customClassNames as any)}>
          {children}
        </Text>
      )
    },
    [BLOCKS.QUOTE]: (_node: ReactNode, children: ReactNode) => (
      <Text as='div' variant='sm' className='pl-2 my-2 italic border-l-4 border-dark-200'>
        {children}
      </Text>
    ),
    [BLOCKS.OL_LIST]: (_node: ReactNode, children: ReactNode) => (
      <Text variant='sm' as='ol' className='pt-4 pb-8 my-2 list-none gutter-l'>
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (_node: ReactNode, children: ReactNode) => {
      return <ul className='pb-8 pl-8 pr-4 lg:gutter list-dash'>{children}</ul>
    },
    [BLOCKS.LIST_ITEM]: (node: ListItem, _children: ReactNode) => {
      // remove html tags from children to prevent extra p tag from rendering within the li tag
      // @ts-expect-error
      const unTaggedChildren = documentToReactComponents(node, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (_node: Paragraph, children) => children,
          [BLOCKS.LIST_ITEM]: (_node: ListItem, children) => children,
        },
      })
      return (
        <li className={cn(getTextStyles({ variant: 'p', weight: 'light' }), 'relative pb-1')}>
          {unTaggedChildren}
        </li>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      if (node.data?.target?.file?.url?.includes('videos')) {
        return <Video videoSrcURL={`https:${node.data.target.file.url}`} autoplay muted controls volume={0} />
      }
      return (
        <div className='inline-block float-right w-6/12 ml-4 md:ml-16 mr-4 mb-4 last:w-full'>
          <GatsbyImage image={node.data.target.gatsbyImageData} alt='image' />
        </div>
      )
    },
  },
})

export const RichText: React.FC<RichTextProps> = ({
  document: { raw, references } = {},
  className = '',
  customClassNames,
  locale,
  translate,
  textStyles,
}) => {
  if (!raw) {
    return null
  }

  return (
    <div className={className}>
      {/* @ts-expect-error */}
      {renderRichText(
        { raw, references: (references ?? []).filter(isDefined) },
        options(locale, translate, textStyles, customClassNames),
      )}
    </div>
  )
}

export default RichText
