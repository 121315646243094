import React from 'react'
import Masonry from 'react-masonry-css'
import { GatsbyImage } from 'gatsby-plugin-image'

import Text from '../Text'
import { Maybe, ContentfulAsset } from '@gql-types'
import Button from '../Button'
import { useLanguage } from '@src/templates'

export interface GalleryProps {
  title?: string | null
  className?: string
  downloadFile?: (filePath: string, fileName: string) => void
  images?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>[] | null | undefined
}

export const Gallery = ({ title, images, downloadFile, className = '' }: GalleryProps) => {
  const { t } = useLanguage()

  if (!images?.length) {
    return null
  }

  return (
    <div className={className}>
      <Text variant='h3' weight='heavy' className='mb-8'>
        {title ?? ''}
      </Text>
      <Masonry
        breakpointCols={{ default: 3, 768: 2, 640: 1 }}
        className='flex w-auto -ml-5'
        columnClassName='bg-clip-padding pl-5'
      >
        {images?.map((img, idx) => {
          const downloadUrl = img?.gatsbyImageData?.images?.fallback?.src
          // @ts-ignore
          const fileName = img?.file?.fileName || 'image'
          return (
            <div className='mb-5'>
              <GatsbyImage key={idx} alt='' image={img?.gatsbyImageData} />
              {downloadFile && (
                <Button variant='text' size='xs' onClick={() => downloadFile(downloadUrl, fileName)}>
                  {t('download')}
                </Button>
              )}
            </div>
          )
        })}
      </Masonry>
    </div>
  )
}

export default Gallery
