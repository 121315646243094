import { useVerticalLockScroll } from '@src/hooks'
import React, { useRef } from 'react'
import { ImageSlider, ImageSliderProps } from '@cmp'

const ImageSliderWithScrollLock = ({ className, cards }: ImageSliderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useVerticalLockScroll(ref)

  return <ImageSlider className={className} cards={cards} ref={ref} />
}

export default ImageSliderWithScrollLock
