import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import cn from 'classnames'

import { FilterBar } from '@cmp'
import { useLanguage } from '@tmp'

import { TixHarpaDateConnection } from '@gql-types'
import { makePath } from '@src/utils/routes'
import ImageSliderWithScrollLock from '@src/connected-components/ImageSlider'

export interface EventHighlightsProps {
  className?: string
  sliderContainerClass?: string
}

export const EventHighlights = ({ className, sliderContainerClass }: EventHighlightsProps) => {
  const { locale, t } = useLanguage()
  const data = useStaticQuery<{
    nextEvents: TixHarpaDateConnection
    recentOnlineSalesStarted: TixHarpaDateConnection
    eventsByCategory: TixHarpaDateConnection
  }>(graphql`
    query EventsQuery {
      nextEvents: allTixHarpaDate(sort: { fields: startDate }, limit: 20) {
        ...eventCardFragment
      }
      recentOnlineSalesStarted: allTixHarpaDate(
        sort: { order: DESC, fields: onlineSaleStart }
        filter: { onlineSaleHasNotStarted: { eq: false } }
        limit: 20
      ) {
        ...eventCardFragment
      }
      eventsByCategory: allTixHarpaDate(
        sort: { fields: startDate }
        filter: { categories: { regex: "/börn/i" } }
        limit: 20
      ) {
        ...eventCardFragment
      }
    }
  `)
  const [activeSlide, setActiveSlide] = useState<keyof typeof data>('nextEvents')
  const { dateFormat } = useLanguage()
  return (
    <div className={cn('relative', className)}>
      <FilterBar
        className='sticky top-0 z-10 text-pink-500 '
        title={t('harpa-highlights')}
        onChange={condition => {
          if (data[condition as keyof typeof data]) {
            setActiveSlide(condition as keyof typeof data)
          }
        }}
        filters={[
          {
            title: t('next-events'),
            condition: 'nextEvents',
          },
          {
            title: t('latest-for-sale'),
            condition: 'recentOnlineSalesStarted',
          },
          {
            title: t('whats-on-for-family'),
            condition: 'eventsByCategory',
          },
          {
            title: t('view-all-events'),
            condition: 'seeAll',
            href: makePath(locale, { type: 'dagskra' }),
          },
        ]}
      />
      <ImageSliderWithScrollLock
        className={cn(sliderContainerClass)}
        cards={data[activeSlide].nodes.map(date => ({
          title: date.name || '',
          image: date.featuredImage?.childImageSharp?.resize?.src || '',
          subtitle: dateFormat(new Date(date.startDate), "dd'.' MMMM '—' p"),
          href: makePath(locale, { type: 'event', slug: date.slug ?? '' }),
        }))}
      />
    </div>
  )
}

export default EventHighlights
