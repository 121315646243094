import React from 'react'
import cn from 'classnames'
import { Text, RichText, RichTextProps } from '@cmp'

export interface PageTextContentProps {
  /**
   * Preformatted text, handled by the RichText component.
   */
  richText?: RichTextProps['document']
  /**
   * Special text that represents the beginning of each article/page and should be
   * formatted as such.
   */
  introText?: string
  className?: string
  locale?: string
  translate?: (word: string) => string
  alt?: boolean
}

export const PageTextContent = ({
  richText,
  introText = '',
  className = '',
  locale,
  translate,
  alt,
}: PageTextContentProps) => {
  let showIntroTextContainer = true

  if (alt && richText?.raw) {
    const content =
      richText?.raw &&
      (JSON.parse(richText?.raw)?.content ?? []).map((item: any, index: number) => {
        let customClassNames = ''

        if (index === 0 && item?.nodeType && item.nodeType.startsWith('heading')) {
          showIntroTextContainer = false
        }

        switch (item.nodeType) {
          case 'heading-3':
            if (index === 0) {
              customClassNames = 'pb-24'
            }

            break
          default:
            break
        }

        return {
          ...item,
          customClassNames,
          index,
        }
      })

    const data = JSON.parse(richText.raw)

    if (data?.content) {
      data.content = content
    }

    richText.raw = JSON.stringify(data)
  }

  return (
    <div className={cn('', className)}>
      {showIntroTextContainer && (
        <Text variant='intro' className='pt-12 pb-12'>
          {introText}
        </Text>
      )}
      {richText && (
        <RichText
          className='overflow-auto'
          document={richText}
          textStyles={{ weight: 'light' }}
          locale={locale}
          translate={translate}
        />
      )}
    </div>
  )
}

export default PageTextContent
