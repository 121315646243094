import React from 'react'
import { Checkbox as Checker } from 'reakit/Checkbox'
import { Text } from '..'
require('./Checkbox.css')
import cn from 'classnames'

export interface CheckboxProps {
  checked: boolean | undefined
  className?: string
  description?: string
  toggle: () => void
  variant?: 'normal' | 'larger'
}

export const Checkbox = ({ className = '', checked, description, toggle, variant = 'normal' }: CheckboxProps) => {
  return (
    <div className={className}>
      <label className={cn(
        'flex',
        {'items-center justify-center': variant === 'normal'}
        )}>
        <Checker
          checked={checked}
          onChange={toggle}
          className='cursor-pointer relative h-[23px] w-[23px] bg-transparent border-dark-400'
        />
        <Text
          variant='p'
          weight={variant === 'normal' ? 'bold': 'normal'}
          className={cn('inline-block pl-3', {'uppercase': variant === 'normal'})}
        >
          {description}
        </Text>
      </label>
    </div>
  )
}

export default Checkbox
