import React from 'react'
import cn from 'classnames'
require('./Card.css')
import { ImageWindow, Text, Link } from '@cmp'

export interface CardProps {
  /**
   * Title of the entity, displayed directly under image.
   */
  title: string
  /**
   * URL to image of entity.
   */
  image: string
  /**
   * Subtitle displayed under title.
   */
  subtitle?: string
  /**
   * URL that takes the user to a details page. Button clicked is arrow icon on bottom of the card.
   */
  href: string
  /**
   * Determines what variant of a image window will be used to display the card image.
   */
  variant?: 0 | 1
  className?: string
}

export const Card = ({ title, image, subtitle, href, variant = 0, className = '' }: CardProps) => (
  <Link to={href} noStyle className={className}>
    <ImageWindow img={image} variant={variant} className={`variant${variant}`} />
    <div className='mt-5 text-current'>
      <Text>{title}</Text>
      <Text weight='light'>{subtitle}</Text>
    </div>
  </Link>
)

export default Card
