import React, { Fragment } from 'react'
import { motion } from 'framer-motion'
import cn from 'classnames'
import { Text } from '..'
import { TextProps } from '../Text'

export interface AnimatedTextProps extends TextProps {
  startPos?: number
  text: string
}

const letterVariants = {
  hidden: {
    y: '90%',
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'circOut',
      duration: 0.6,
    },
  },
}

export const AnimatedText = ({ as, className = '', text, weight, variant }: AnimatedTextProps) => {
  return (
    <Text as={as} variant={variant} weight={weight} className={className} aria-label={text}>
      {text.split(' ').map((word, wordIndex) => (
        <Fragment key={wordIndex}>
          <span className='whitespace-nowrap' aria-hidden>
            {word.split('').map((char, idx) => (
              <motion.span
                className={cn('relative inline-block w-auto', {
                  'capitalize -mt-5 pt-5': wordIndex === 0 && idx === 0,
                })}
                key={`${word}${char}${idx}`}
                initial='hidden'
                animate='show'
                variants={{
                  ...letterVariants,
                  show: {
                    ...letterVariants.show,
                    transition: { ...letterVariants.show.transition, delay: (Math.random() * 1) / 3 },
                  },
                }}
              >
                {char}
              </motion.span>
            ))}
          </span>{' '}
        </Fragment>
      ))}
    </Text>
  )
}

export default AnimatedText
