import React, { useEffect } from 'react'
import { AnnualMenu, Menu, Footer, BaseLayout, BaseLayoutProps } from '@tmp'
import cn from 'classnames'
import Locale from '@src/utils/locale'
import { Route } from '@src/utils/routes'
import { useCookieBanner } from '@src/hooks/useCookieBanner'
import { useLanguage } from '../context'
export interface MainLayoutProps extends BaseLayoutProps {
  localizedUrls: Array<{ locale: Locale; route: Route }>
  className?: string
  hideMenu?: boolean
  footerPoly?: boolean
  isAnnualReport?: boolean
  annualTag: string
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  seo,
  defaultSeoOverwrites,
  localizedUrls,
  className,
  hideMenu,
  footerPoly = true,
  isAnnualReport = false,
  annualTag,
}) => {
  const { setBannerLanguage } = useCookieBanner()
  const { locale } = useLanguage()

  useEffect(() => setBannerLanguage(locale), [locale])

  const renderMenu = () => {
    if (isAnnualReport) {
      return <AnnualMenu localizedUrls={localizedUrls} isAnnualReport annualTag={annualTag} />
    } else {
      return <Menu localizedUrls={localizedUrls} />
    }
  }

  return (
    <BaseLayout seo={seo} defaultSeoOverwrites={defaultSeoOverwrites}>
      {!hideMenu && renderMenu()}
      <main
        className={cn('xsm:overflow-hidden', className, {
          'pb-28': footerPoly,
        })}
      >
        {children}
      </main>
      <Footer footerPoly={footerPoly} />
    </BaseLayout>
  )
}

export default MainLayout
