import React from 'react'
import { Footer, FooterProps } from '@cmp'
import { FooterFragmentFragment } from '@gql-types'
import { objectToPath } from '@src/utils/objectToPath'
import { useLanguage } from '@src/templates'

interface FooterMapping {
  [key: string]: {
    component: () => React.FunctionComponent<FooterProps>
    dataToProps: (data: FooterFragmentFragment) => FooterProps
  }
}

const mappings: FooterMapping = {
  ContentfulFooter: {
    component: () => Footer,
    // @ts-expect-error
    dataToProps: (data: FooterFragmentFragment) => {
      const { t } = useLanguage()
      return {
        translate: t,
        contactDetailsTitle: data.contactDetailsTitle ?? '',
        openingHours: {
          label: data.openingHours?.label ?? '',
          link: objectToPath(data?.openingHours),
        },
        postlist: {
          label: data.postlist?.label ?? '',
          link: objectToPath(data?.postlist),
        },
        contactDetails: {
          address: data.address ?? '',
          zip: data.zip ?? '',
          city: data.city ?? '',
          telephone: data.telephone ?? '',
          boxOffice: data.boxOffice ?? '',
          email: data.email ?? '',
        },
        linkSections: (data.linkSections ?? []).map(section => ({
          title: section?.title ?? '',
          links: (section?.links ?? []).map(link => ({
            label: link?.label ?? '',
            link: objectToPath(link),
          })),
        })),
        socials: (data?.socialLinks ?? []).map(social => {
          return {
            button: {
              label: social?.label ?? '',
              link: objectToPath(social),
            },
          }
        }),
      }
    },
  },
}

export default mappings
