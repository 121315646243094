import React, { Fragment } from 'react'
import {
  Feature,
  HeroHeader,
  ImageSlider,
  OverviewBar,
  PageList,
  PageTextContent,
  RichTextProps,
  StaffGrid,
  QandA,
  BookingInfo,
  InfoTable,
  FileTable,
  RelatedContent,
  Gallery,
  Footage,
  EmbeddedContent,
  KeyNumberSection,
  ChartsJs,
} from '@cmp'
import cn from 'classnames'
import { Maybe, PageFragmentFragment } from '@gql-types'
import { objectToPath } from '@src/utils/objectToPath'
import { downloadFile, isDefined } from '@src/utils/helpers'
import { ContentfulRichTextGatsbyReference } from 'gatsby-source-contentful/rich-text'
import { useLanguage } from '@src/templates'
import EventCategoryList from '@src/query-components/EventCategoryList'
import { MEDIA_PAGE_EN, MEDIA_PAGE_IS } from '@src/utils/contants'

type Content = NonNullable<NonNullable<PageFragmentFragment['pageContent']>[number]>

const renderContent = (
  data: Content,
  location: Location,
  annualTag: string,
  isAnnualReport: boolean,
  nextType?: string,
): JSX.Element => {
  const { t, locale } = useLanguage()
  const isStaffPage = location?.pathname?.includes('humanresources') || location.pathname.includes('mannaudur')
  const isMediaPage = location?.pathname?.includes(MEDIA_PAGE_IS) || location.pathname.includes(MEDIA_PAGE_EN)
  switch (data.__typename) {
    case 'ContentfulEventCategoryList': {
      // @ts-expect-error
      return <EventCategoryList selectedCategory={data?.evenCattListCategory ?? 'classicSundays'} />
    }
    case 'ContentfulImageSlider': {
      return (
        <ImageSlider
          cards={(data?.cards ?? []).map(card => ({
            title: card?.title ?? '',
            date: card?.date,
            image: card?.cardImage?.fluid?.src ?? '',
            href: objectToPath(card?.link),
          }))}
        />
      )
    }
    case 'ContentfulOverviewBar': {
      const labels = (data.labels ?? []).filter(isDefined)
      return <OverviewBar labels={labels} title={data.title ?? ''} className='hidden md:grid' />
    }

    case 'ContentfulPageTextContent': {
      if (data?.alternative) {
        return (
          <div className='gutter border-b pb-11 md:pb-32'>
            <PageTextContent
              alt
              className='w-full mx-auto lg:w-10/12'
              richText={richTextProps(data.richText ?? undefined)}
              translate={t}
              locale={locale}
            />
          </div>
        )
      }

      return (
        <div className='gutter'>
          <PageTextContent
            className='w-full pt-12 mx-auto lg:w-8/12'
            introText={data.introText?.introText ?? undefined}
            richText={richTextProps(data.richText ?? undefined)}
            translate={t}
            locale={locale}
          />
        </div>
      )
    }

    case 'ContentfulIFrame': {
      return (
        <div className='gutter'>
          <EmbeddedContent
            storageKey={data.key?.name}
            terms={data.terms}
            content={data.iframe?.iframe}
            locale={locale}
            translate={t}
          />
        </div>
      )
    }

    case 'ContentfulHeroHeader': {
      return (
        <HeroHeader
          title={data.title ?? undefined}
          intro={data.intro?.intro ?? undefined}
          image={data.headerImage?.gatsbyImageData}
          className='gutter'
          annualTag={annualTag}
          isAnnualReport={isAnnualReport}
        />
      )
    }
    case 'ContentfulStaffGrid': {
      return (
        <StaffGrid
          isStaffPage={isStaffPage}
          title={data.title ?? undefined}
          staffMembers={(data.staffMembers ?? []).filter(isDefined).map(member => ({
            name: member.name ?? '',
            role: member.role ?? '',
            department: member.department ?? '',
            email: member.email ?? undefined,
            photo: member.photo?.gatsbyImageData,
            phone: member.phoneNumber ?? undefined,
          }))}
        />
      )
    }

    case 'ContentfulFeature': {
      const button = data.button ?? undefined
      return (
        <Feature
          squareImage={!!data?.squareImage}
          className='pb-12 md:pb-24 gutter'
          title={data.title ?? ''}
          containImage={data.containImage}
          button={
            button && {
              label: button.label ?? '',
              link: objectToPath(button),
            }
          }
          imageDescription={data.imageDescription ?? ''}
          secondaryImageDescription={data.secondaryImageDescription ?? ''}
          featureImage={data.featureImage?.gatsbyImageData}
          description={data.description?.description ?? undefined}
          richText={richTextProps(data.richText ?? undefined)}
        />
      )
    }
    case 'ContentfulPageSimpleLists': {
      const lists = [...(data.listOne ?? []), ...(data.listTwo ?? [])]
      return <PageList lists={lists} className='mt-28 md:mt-45.5' />
    }

    case 'ContentfulRelatedPages': {
      return <RelatedContent title={data.title} linkedPages={data.linkedPages} className='mt-20 gutter' />
    }
    case 'ContentfulTable': {
      return (
        <InfoTable
          title={data.title}
          // @ts-expect-error
          tableData={data.data?.tableData}
          tableBackgroundColor={data.tableBackgroundColor?.[0] ?? 'green'}
          className='w-full mx-auto mt-16 lg:w-12/12 xl:w-9/12'
        />
      )
    }
    case 'ContentfulFaqList': {
      return (
        <div className='gutter'>
          {/* @ts-expect-error */}
          <QandA className='w-full pt-20 m-auto lg:w-8/12' title={data.title} items={data.items} />
        </div>
      )
    }
    case 'ContentfulBookingInfo': {
      return (
        <div className='gutter'>
          <BookingInfo
            className='w-full pt-20 lg:w-8/12 '
            title={data.title}
            description={data.infoText}
            departments={data.departments}
          />
        </div>
      )
    }
    case 'ContentfulFiles': {
      return (
        <div className='gutter'>
          <FileTable
            buttonLabel={`${t('download')} PDF`}
            title={data.title}
            files={data.files}
            className='w-full pt-20 m-auto lg:w-8/12'
          />
        </div>
      )
    }
    case 'ContentfulImageGallery': {
      // start by testing this for the media page only, might expand this later
      const downloadFileFunc = isMediaPage ? downloadFile : undefined
      return (
        <Gallery title={data.title} images={data.image} downloadFile={downloadFileFunc} className='pt-20 gutter' />
      )
    }
    case 'ContentfulGraf': {
      return (
        <div className='pt-20'>
          <ChartsJs
            title={data.title}
            data={data.chartData}
            type={data.type}
            oldColors={data?.useOldColors}
            className='pt-20'
          />
        </div>
      )
    }
    case 'ContentfulFootage': {
      return (
        <div className='pt-20 gutter'>
          <Footage
            title={data.title}
            videoSrcURL={data.link || data.videoFile?.file?.url}
            image={data.fallbackImage?.gatsbyImageData}
            className='w-full m-auto lg:w-8/12'
            autoplay={data.autoplay || false}
            controls={data.showControls || false}
            muted={data.showControls || false}
            translate={t}
            locale={locale}
          />
        </div>
      )
    }
    case 'ContentfulKeyNumberSection': {
      return (
        <div className='pt-20'>
          <KeyNumberSection
            title={data.title ?? ''}
            data={data.keyNumberList}
            boxed={data?.boxedItems}
            color={data?.boxedBackgroundColor}
          />
        </div>
      )
    }
    default: {
      return <div></div>
    }
  }
}

const richTextProps = (
  doc: {
    raw?: Maybe<string>
    references?: Maybe<Array<Maybe<ContentfulRichTextGatsbyReference>>>
  } = {},
): NonNullable<RichTextProps['document']> => ({
  raw: doc.raw ?? '',
  references: (doc.references ?? []).filter(isDefined),
})

export const PageContent = ({
  content,
  location,
  annualTag = 'ANNUAL_REPORT_FRONT_2023',
  isAnnualReport = false,
}: {
  location: Location
  content: PageFragmentFragment['pageContent']
  annualTag?: string
  isAnnualReport?: boolean
}): JSX.Element => {
  const definedElements = (content ?? []).filter(isDefined)
  const elements = definedElements.map((data, idx) => {
    const nextType = definedElements[idx + 1]?.__typename ?? ''
    return <Fragment key={idx}>{renderContent(data, location, annualTag, isAnnualReport, nextType)}</Fragment>
  })
  return <>{elements}</>
}
