import React from 'react'
import cn from 'classnames'
import { Text } from '@cmp'

type keyNumber = {
  label: string
  value: string
  color: string
}

export interface KeyNumberSectionProps {
  title?: string
  className?: string
  data: keyNumber[]
  color?: string
  boxed?: boolean
}

export const KeyNumberSection = ({ title, data, className = '', color, boxed }: KeyNumberSectionProps) => {
  let boxColor = 'transparent'

  switch (color) {
    case 'blue':
      boxColor = 'rgba(0, 97, 98, 0.08)'
      break
    case 'green':
      boxColor = 'rgba(245, 208, 70, 0.08)'
      break
    case 'red':
      boxColor = 'rgba(182, 0, 21, 0.08)'
      break
    default:
      break
  }

  return (
    <section className={cn('border-t gutter', className)}>
      {title && (
        <Text variant='h3' weight='heavy' className='pt-6'>
          {title}
        </Text>
      )}
      {boxed ? (
        <div className='flex flex-row flex-wrap pt-32 gap-6'>
          {data.map(item => {
            const variant = item.value.length > 5 ? 'h2' : 'h1'

            return (
              <div className='w-full lg:max-w-[calc(50%-12px)] border p-4 pt-8' style={{ background: boxColor }}>
                <div className='flex flex-col justify-between min-h-[100px] md:min-h-[180px] lg:min-h-[280px]'>
                  <div>
                    <Text as='h3' variant='medium' weight='light' className='mb-2'>
                      {item.label}
                    </Text>
                  </div>
                  <div className='truncate w-full overflow-hidden inline-block whitespace-nowrap'>
                    <Text variant={variant} as='span' weight='heavy'>
                      {item.value}
                    </Text>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className='flex flex-row flex-wrap pt-32'>
          {data.map(item => {
            return (
              <div className='w-full lg:w-6/12'>
                <div className='flex flex-col'>
                  <Text as='h3' variant='medium' weight='light' className='mb-2'>
                    {item.label}
                  </Text>
                  <Text variant='h3Alt' weight='heavy' className='mb-8'>
                    {item.value}
                  </Text>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}
export default KeyNumberSection
