import React from 'react'
import { Footage, Text, RichTextProps, RichText } from '@cmp'
import Masonry from 'react-masonry-css'

import { HyphenText } from '@connected'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ContentfulFootage } from '@gql-types'
import { footageExists } from '@src/utils/helpers'

export interface NewsArticlePageProps {
  title: string
  createdAt: string
  gallery: [{ gatsbyImageData: IGatsbyImageData }]
  footage: ContentfulFootage
  updatedAt?: string
  introText?: string
  photo: IGatsbyImageData
  content: RichTextProps['document']
  locale?: string
  translate: (word: string) => string
}

export const NewsArticlePage = ({
  title,
  createdAt,
  footage,
  gallery,
  introText,
  photo,
  content,
  locale,
  translate,
}: NewsArticlePageProps) => {
  return (
    <div className='flex flex-col items-center w-full'>
      <div className='w-11/12 pt-20 lg:w-8/12 mb-36'>
        <Text className='my-6'>{createdAt}</Text>
        <HyphenText variant='h2' className='my-6'>
          {title}
        </HyphenText>
        <Text variant='intro' className='my-6'>
          {introText}
        </Text>
        {photo && (
          <GatsbyImage
            className='w-full py-6 mx-auto window-clip max-h-80'
            imgStyle={{ objectPosition: '50% 25%' }}
            alt=''
            image={photo}
          />
        )}
        {content && <RichText document={content} className='my-6' />}
        {footageExists(footage) && (
          <div className='lg:ml-auto pb-50'>
            <Footage
              videoSrcURL={footage.link || footage?.videoFile?.file?.url}
              image={footage?.fallbackImage?.gatsbyImageData}
              autoplay
              controls
              volume={0}
              muted
              loop
              locale={locale}
              translate={translate}
            />
          </div>
        )}
        {gallery?.length ? (
          <Masonry
            breakpointCols={{ default: 3, 800: 2 }}
            className='flex w-auto -ml-5'
            columnClassName='bg-clip-padding pl-5'
          >
            {gallery?.map((img, idx) => (
              <GatsbyImage key={idx} alt='' image={img?.gatsbyImageData} className='mb-5' />
            ))}
          </Masonry>
        ) : null}
      </div>
    </div>
  )
}
export default NewsArticlePage
