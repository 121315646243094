import React, { ReactNode } from 'react'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { Text, Link } from '@cmp'

export interface HtmlProps {
  children: string
}

const nodeReplacer = (node: any, replace: any) => {
  if (!node.children) {
    return
  }
  return replace(domToReact(node.children, options), node)
}

const mapper = {
  p: (children: ReactNode) => <Text className='mb-6 font-light'>{children}</Text>,
  b: (children: ReactNode) => (
    <Text weight='bold' as='span'>
      {children}
    </Text>
  ),
  strong: (children: ReactNode) => (
    <Text weight='bold' as='span'>
      {children}
    </Text>
  ),
  li: (children: ReactNode) => <Text as='li'>{children}</Text>,
  // @ts-expect-error
  a: (children: ReactNode, node) => <Link to={node.attribs.href}>{children}</Link>,
  // @ts-expect-error
  img: (children: ReactNode, node) => (
    <img src={node.attribs?.src?.replace('http://', 'https://')} alt={node.attribs?.alt || ''} />
  ),
   // @ts-expect-error
  iframe: (children: ReactNode, node) => {
    return (
      <div className='aspect-video h-[315px] relative'>
        <iframe
          src={node.attribs?.src}
          title={node.attribs?.title}
          width={node.attribs?.width}
          height={node.attribs?.height}
          frameBorder={node.attribs?.frameborder}
          allowFullScreen={node.attribs?.allowfullscreen}
        />
      </div>
  )},
}

const options: HTMLReactParserOptions = {
  replace: domNode => {
    // @ts-expect-error
    if (mapper[domNode.name]) return nodeReplacer(domNode, mapper[domNode.name])
  },
  trim: true,
}

export const Html = ({ children }: HtmlProps) => <div>{parse(children, options)}</div>

export default Html
