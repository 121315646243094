import React from 'react'
import { Text, Link } from '@cmp'
import cn from 'classnames'

export interface FooterProps {
  /**
   * All contact details in a single object.
   */
  contactDetails: ContactDetailsType
  /**
   * A list of all social links.
   */
  socials: External[]
  openingHours: { label: string; link: string }
  postlist: { label: string; link: string }
  openCookieSettings: () => void
  /**
   * Optional title to have at the top of the contact details.
   */
  contactDetailsTitle?: string
  className?: string
  translate: (word: string) => string
  linkSections: {
    title: string
    links: {
      label: string
      link: string
    }[]
  }[]
  footerPoly?: boolean
}

type External = {
  button: {
    label: string
    link: string
  }
}

type ContactDetailsType = {
  /**
   * Address, rendered inline with zip and city.
   */
  address: string
  /**
   * Zip, rendered inline with address and city.
   */
  zip: string
  /**
   * City, rendered inline with address and zip.
   */
  city: string
  /**
   * Telephone number, prefaced with "Sími:" in code.
   */
  telephone: string
  /**
   * Box office number, prefaced with "Miðasala:" in code.
   */
  boxOffice: string
  /**
   * Email address, will take user to mail app when clicked.
   */
  email: string
}

export const Footer = ({
  contactDetails,
  contactDetailsTitle = '',
  openingHours,
  socials,
  postlist,
  className,
  translate,
  linkSections,
  openCookieSettings,
  footerPoly = true,
}: FooterProps) => {
  return (
    <footer className={cn('relative text-dark-400', className)}>
      {footerPoly && <div
        className='absolute w-full h-32 bg-white -top-32'
        style={{
          clipPath: 'polygon(30.6% 56.4%, 100% 0, 100% 100%, 0 100%, 0 0)',
        }}
      ></div>}
      <div className={`flex flex-wrap ${footerPoly && 'mt-32'} bg-white border-t pb-28 border-dark-400 gutter`}>
        <ul className='flex flex-col w-full sm:w-6/12 md:w-3/12 lg:w-3/12 xl:w-2/12'>
          <li className='pt-6 pb-8'>
            <Text variant='sm' weight='bold'>
              {contactDetailsTitle}
            </Text>
          </li>
          {openingHours && (
            <li>
              <Link
                className='inline-block link'
                textStyles={{ variant: 'sm', weight: 'bold' }}
                to={openingHours.link}
              >
                {openingHours.label ?? ''}
              </Link>
            </li>
          )}
          <li className='pt-1'>
            <Text variant='sm' className='inline-block'>
              {contactDetails.address}, {contactDetails.zip} {contactDetails.city}
            </Text>
          </li>
          <li className='flex pt-1'>
            <Text variant='sm'>{translate('phone-number')}:&nbsp;</Text>
            <Link
              to={`tel:+354-${contactDetails.telephone}`}
              textStyles={{ variant: 'sm' }}
              className='inline-block link'
            >
              {`${contactDetails.telephone}`}
            </Link>
          </li>
          <li className='flex pt-1'>
            <Text variant='sm'>{translate('ticket-sales')}:&nbsp;</Text>
            <Link
              to={`tel:+354-${contactDetails.boxOffice}`}
              textStyles={{ variant: 'sm' }}
              className='inline-block link'
            >
              {`${contactDetails.boxOffice}`}
            </Link>
          </li>
          <li className='pt-1'>
            <Link
              textStyles={{ variant: 'sm' }}
              to={`mailto:${contactDetails.email}`}
              className='inline-block link'
            >
              {contactDetails.email}
            </Link>
          </li>
          <li className='pt-1'>
            <Link to={postlist.link} textStyles={{ variant: 'sm' }} className='inline-block link'>
              {postlist.label}
            </Link>
          </li>
        </ul>
        {linkSections.map((section, idx) => (
          <div className='w-full sm:w-6/12 md:w-3/12 xl:w-2/12' key={idx}>
            <div className={idx === 1 ? 'md:border-l md:pl-5 mt-5 md:mt-0' : 'sm:pl-5 sm:border-l mt-5 sm:mt-0'}>
              <Text variant='sm' weight='bold' className='pt-6 mb-8'>
                {section.title}
              </Text>
            </div>
            <ul className={idx === 1 ? 'md:pl-5' : 'sm:pl-5'}>
              {section.links.map(({ link, label }, idx) => (
                <li className={cn({ 'pt-1': idx !== 0 })} key={idx}>
                  <Link to={link} textStyles={{ variant: 'sm' }} className='inline-block link'>
                    {label}
                  </Link>
                </li>
              ))}
              {idx === 1 && (
                <li className='pt-1'>
                  <button className='inline-block focus:outline-none link' onClick={() => openCookieSettings()}>
                    <Text variant='sm'>{translate('cookies')}</Text>
                  </button>
                </li>
              )}
            </ul>
          </div>
        ))}
        <div className='w-full ml-auto md:w-3/12 xl:w-4/12'>
          <div className='flex flex-wrap justify-between pt-4 md:border-l md:pt-0'>
            {socials.map(({ button }, idx) => (
              <Link
                key={idx}
                className='mt-6 md:ml-5'
                textStyles={{ variant: 'sm', weight: 'bold' }}
                to={button.link}
                noStyle
                noBorder
              >
                {button.label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
