import React, { useState, useRef, ReactNode, AllHTMLAttributes, useEffect, CSSProperties, Fragment } from 'react'
import cn from 'classnames'
import { navigate } from 'gatsby-link'
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit/Disclosure'
import { motion } from 'framer-motion'
import { useLocation } from '@reach/router'
import { Icon, Link, Input } from '@cmp'
import { useLockBodyScroll } from '@hooks'
import { Button as ReaButton } from 'reakit/Button'
import { useMenuState, Menu, MenuButton, MenuItem, MenuStateReturn } from 'reakit/Menu'
import { makePath } from '@src/utils/routes'
import Locale from '@src/utils/locale'

export interface NavBarProps {
  languageHref: string
  languageTitle: string
  searchTitle: string
  menuTitle: string
  closeTitle: string
  searchPath: string
  locale: Locale
  navigation: (
    | {
        title: string
        href: string
        subMenu?: undefined
      }
    | {
        title: string
        subMenu: {
          title: string
          href: string
        }[]
        href?: undefined
      }
  )[]
  isAnnualReport?: boolean
  annualTag?: string
}

const searchInputVariants = {
  visible: { opacity: 1, width: 150, zIndex: 10, transition: { type: 'tween' } },
  hidden: { opacity: 0, width: 100, zIndex: -1, transition: { type: 'tween' } },
}

export const NavBar = ({
  navigation,
  languageHref,
  languageTitle,
  searchTitle,
  locale,
  menuTitle,
  closeTitle,
  searchPath,
  isAnnualReport = false,
  annualTag = 'ANNUAL_REPORT_2023',
}: NavBarProps) => {
  const [hasOpenMenu, setHasOpenMenu] = useState(false)
  const location = useLocation()

  const year = annualTag.replace(/^\D+/g, '')

  useEffect(() => {
    if (window) {
      /* 
        preloads page data for searchResults page, prevents page from 
        seeming unresposive when initiating search on first visit
      */

      // @ts-ignore
      window.___loader?.hovering(makePath(locale, { type: 'search' }))
    }
  }, [])
  return (
    <nav
      className={cn('relative transition-colors flex items-center h-header gutter z-[101]', {
        'text-dark-400': hasOpenMenu,
      })}
    >
      <div className='flex justify-center order-2 w-4/12 lg:order-1 lg:justify-start z-1'>
        <Link
          to={isAnnualReport ? makePath(locale, { type: 'annual', slug: year }) : makePath(locale, { type: 'frontpage' })}
          noStyle
        >
          <Icon name='HarpaHorizontal' className='w-[133px] hidden lg:block' />
          <Icon name='HarpaSingle' className='w-[55px] lg:hidden' />
        </Link>
      </div>
      <div className='z-10 hidden lg:w-6/12 lg:order-2 lg:flex lg:justify-center'>
        {navigation.map((nav, idx) => {
          if (locale === 'en' && (nav.href === '/fundarherbergi' || nav.href === '/en/annual-and-sustainability-report-2023')) {
            return null 
          }
          const last = navigation.length - 1 === idx
          const classes = cn('z-1 helgi min-w-fit', {
            'mr-8': !last,
          })
          if (nav.subMenu) {
            return (
              <ButtonWithMenu
                key={idx}
                setHasOpenMenu={setHasOpenMenu}
                subMenu={nav.subMenu}
                className={classes}
                menuItemClass='inline-block'
              >
                {nav.title}
              </ButtonWithMenu>
            )
          }
          return (
            <NavButton
              key={idx}
              href={nav.href}
              className={classes}
              selected={location?.pathname?.includes(nav.href)}
            >
              {nav.title}
            </NavButton>
          )
        })}
      </div>
      <div className='flex order-1 w-4/12 lg:justify-end z-1 lg:order-3'>
        <Search className='hidden mr-8 lg:flex' searchPath={searchPath}>
          {searchTitle}
        </Search>
        {!isAnnualReport ? <NavButton href={languageHref}>{languageTitle}</NavButton> : null}
      </div>
      <div className='flex justify-end order-3 w-4/12 lg:hidden'>
        <MobileMenu
          navigation={navigation}
          setHasOpenMenu={setHasOpenMenu}
          searchTitle={searchTitle}
          menuTitle={menuTitle}
          closeTitle={closeTitle}
          searchPath={searchPath}
        />
      </div>
    </nav>
  )
}

const MobileMenu = ({
  setHasOpenMenu,
  navigation,
  searchTitle,
  menuTitle,
  closeTitle,
  searchPath,
}: {
  setHasOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
  navigation: NavBarProps['navigation']
  searchTitle: string
  menuTitle: string
  closeTitle: string
  searchPath: string
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()
  const onSearch: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    if (searchTerm.length > 0) {
      navigate(`${searchPath}?term=${searchTerm}`)
      disclosure.hide()
    }
  }

  const disclosure = useDisclosureState({ animated: true })
  const contentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }
  useLockBodyScroll(disclosure.visible)

  useEffect(() => {
    setHasOpenMenu(disclosure.visible)
    window.scrollTo(0, 0)
  }, [disclosure.visible, setHasOpenMenu])

  return (
    <>
      <Disclosure as={NavButton} className='z-1' {...disclosure}>
        {disclosure.visible ? closeTitle : menuTitle}
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        aria-label={menuTitle}
        as={motion.div}
        initial='hidden'
        animate={disclosure.visible ? 'visible' : 'hidden'}
        variants={contentVariants}
        onAnimationComplete={() => {
          disclosure.stopAnimation()
        }}
        className='fixed top-0 bottom-0 left-0 right-0 overflow-auto bg-white'
      >
        <div className='h-full pt-header'>
          <div className='flex flex-col justify-between h-full pt-10 mx-auto gutter'>
            <div className='flex flex-col items-start min-h-[950px] justify-start gap-12'>
              {navigation.map((nav, idx) => {
                const classes = cn('z-1 text-left font-light text-nav-mobile-main focus:outline-none')
                if (nav.subMenu) {
                  return (
                    <ButtonWithMenu
                      subMenu={nav.subMenu}
                      key={idx}
                      className={classes}
                      menuClass='overflow-hidden focus:outline-none helgi min-w-fit -mb-6 -mt-8'
                      menuContainerClass=''
                      menuContentClass='flex flex-col items-start text-nav-mobile-sub font-light'
                      hideMenuItemDelimiter
                      hideBackdrop
                      isMobile={true}
                      menuStyles={{ position: 'static' }}
                    >
                      {menu => `${nav.title} ${menu.visible ? '-' : '+'}`}
                    </ButtonWithMenu>
                  )
                }
                return (
                  <NavButton
                    key={idx}
                    href={nav.href}
                    className={classes}
                    selected={location?.pathname?.includes(nav.href)}
                  >
                    {nav.title}
                  </NavButton>
                )
              })}
              <form onSubmit={onSearch} className='py-10 w-full'>
                <Input
                  leadingIconName='Search'
                  value={searchTerm}
                  type='search'
                  placeholder={searchTitle.toUpperCase()}
                  onChange={e => {
                    setSearchTerm(e.target.value)
                  }}
                />
              </form>
            </div>
          </div>
        </div>
      </DisclosureContent>
    </>
  )
}

const contentVariants = {
  visible: { opacity: 1, height: 'auto' },
  hidden: { opacity: 0, height: 0 },
  menuItemVisible: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.1, // Adjust the multiplier to control the delay between each item
    },
  }),
  menuItemHidden: { opacity: 0 },
};

const ButtonWithMenu = ({
  className,
  children,
  setHasOpenMenu,
  subMenu,
  menuItemClass,
  hideMenuItemDelimiter,
  hideBackdrop,
  menuClass = 'absolute left-0 w-full overflow-hidden bg-white',
  menuContentClass = 'py-20 mx-auto font-light text-center text-nav lg:w-10/12',
  menuContainerClass = 'pt-header',
  menuStyles,
  isMobile = false,
}: {
  className?: string
  children: string | ((menu: MenuStateReturn) => string)
  setHasOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>
  subMenu: {
    title: string
    href: string
  }[]
  menuItemClass?: string
  hideMenuItemDelimiter?: boolean
  hideBackdrop?: boolean
  menuClass?: string
  menuContentClass?: string
  menuContainerClass?: string
  menuStyles?: CSSProperties
  isMobile?: boolean
}) => {
  const menu = useMenuState({ animated: true, orientation: undefined, loop: true })
  const location = useLocation()

  useEffect(() => {
    setHasOpenMenu?.(menu.visible)
  }, [menu.visible, setHasOpenMenu])

  const title = typeof children === 'string' ? children : children?.(menu)
  const selectedMenu = subMenu?.filter(sub => location?.pathname?.includes(sub.href))
  const hasSelectedSubMenu = selectedMenu && location?.pathname?.includes(selectedMenu?.[0]?.href)

  return (
    <>
      <MenuButton
        as={NavButton}
        active={menu.visible}
        {...menu}
        className={className}
        selected={hasSelectedSubMenu}
      >
        {title}
      </MenuButton>
      <Menu
        {...menu}
        aria-label={title as string}
        as={motion.div}
        initial='hidden'
        animate={menu.visible ? 'visible' : 'hidden'}
        variants={contentVariants}
        onAnimationComplete={() => {
          menu.stopAnimation()
        }}
        className={menuClass}
        style={{ transform: 'none', inset: 'none', ...menuStyles }}
      >
        <div className={menuContainerClass}>
          <div className={menuContentClass}>
            {subMenu.map((sub, idx) => {
              return (
                isMobile ? <motion.div
                  key={idx}
                  variants={contentVariants}
                  initial="menuItemHidden"
                  animate={menu.visible ? "menuItemVisible" : "menuItemHidden"}
                  custom={idx} // Passing index as custom prop for dynamic delay
                >
                  <MenuItem {...menu} as={Link} noStyle className={menuItemClass} to={sub.href}>
                    {sub.title}
                  </MenuItem>
                  {!hideMenuItemDelimiter && subMenu.length - 1 > idx && ' — '}
                </motion.div> : 
                <Fragment key={idx}>
                  <MenuItem {...menu} as={Link} noStyle className={menuItemClass} to={sub.href}>
                    {sub.title}
                  </MenuItem>
                  {!hideMenuItemDelimiter && subMenu.length - 1 > idx && ' — '}
                </Fragment>
              )
            })}
          </div>
        </div>
      </Menu>
      {!hideBackdrop && (
        <div
          className={cn('hidden lg:block bg-black transition-colors', {
            'bg-opacity-0': !menu.visible,
            'fixed top-0 bottom-0 left-0 right-0 bg-opacity-25 -z-1': menu.visible,
          })}
        />
      )}
    </>
  )
}

const Search = ({
  className,
  children,
  searchPath,
}: {
  className: string
  children: ReactNode
  searchPath: string
}) => {
  const [showInput, setShowInput] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const searchRef = useRef(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const onSearch: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    if (searchTerm.length > 0) {
      navigate(`${searchPath}?term=${searchTerm}`)
    }
  }
  return (
    <div className={cn('relative flex align-center', className)}>
      <NavButton
        className={cn('transition-transform transform', {
          'opacity-0 -translate-x-2': showInput,
        })}
        onClick={e => {
          e.preventDefault()
          setShowInput(!showInput)
          if (searchInputRef?.current) searchInputRef.current.focus()
        }}
      >
        {children}
      </NavButton>

      <motion.form
        ref={searchRef}
        initial='hidden'
        animate={showInput ? 'visible' : 'hidden'}
        variants={searchInputVariants}
        onSubmit={onSearch}
        className={`absolute right-0 top-1/2 transform -translate-y-1/2`}
      >
        <Input
          ref={searchInputRef}
          leadingIconName='Search'
          onBlur={() => {
            setShowInput(false)
          }}
          tabIndex={showInput ? 0 : -1}
          value={searchTerm}
          type='search'
          onChange={e => {
            setSearchTerm(e.target.value)
          }}
        />
      </motion.form>
    </div>
  )
}

const NavButton = React.forwardRef<
  any,
  {
    children?: ReactNode
    className?: string
    active?: boolean
    selected?: boolean
    onClick?: NativeProps['onClick']
    href?: string
  }
>(({ children, className, active, selected, ...props }, ref) => {
  return (
    <ButtonWrapper
      ref={ref}
      className={cn(className, 'py-1 relative group overflow-hidden focus:outline-none')}
      {...props}
    >
      <span className='font-bold text-transparent'>{children}</span>
      <span
        className={cn(
          'absolute top-0 bottom-0 left-0 right-0 flex items-center justify-left font-bold transition-transform duration-300 transform -translate-y-full',
          {
            'translate-y-0': active,
          },
        )}
      >
        {children}
      </span>
      <span
        className={cn(
          'absolute top-0 bottom-0 left-0 right-0 flex font-light items-center justify-left transition-transform duration-300 transform',
          {
            'translate-y-full': active,
          },
        )}
      >
        {children}
      </span>
    </ButtonWrapper>
  )
})

type NativeProps = AllHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>

const ButtonWrapper = React.forwardRef<
  any,
  {
    children?: ReactNode
    className?: string
    href?: string
    onClick?: NativeProps['onClick']
  }
>(({ children, className, href, onClick, ...props }, ref) => {
  return href ? (
    <ReaButton ref={ref} onClick={onClick} as={Link} to={href} noStyle className={className} {...props}>
      {children}
    </ReaButton>
  ) : (
    <ReaButton ref={ref} onClick={onClick} className={className} {...props}>
      {children}
    </ReaButton>
  )
})

export default NavBar
