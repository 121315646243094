import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import cn from 'classnames'

import { useLanguage } from '@tmp'

import { TixHarpaDateConnection } from '@gql-types'
import { makePath } from '@src/utils/routes'
import ImageSliderWithScrollLock from '@src/connected-components/ImageSlider'

export interface EventCategoryListProps {
  className?: string
  sliderContainerClass?: string
  selectedCategory:
    | 'classicSundays'
    | 'music'
    | 'theater'
    | 'dance'
    | 'exhibition'
    | 'rockandpop'
    | 'conventions'
    | 'classicContemporary'
    | 'movieconcert'
    | 'jazz'
    | 'standup'
    | 'opera'
    | 'icelandicsymphony'
    | 'advent'
    | 'jol'
    | 'jolatonleikar'
    | 'aramota'
    | 'tour'
}

export const EventCategoryList = ({
  className,
  sliderContainerClass,
  selectedCategory,
}: EventCategoryListProps) => {
  const { locale } = useLanguage()

  // todo: find a more dynamic way to load these without having to use a static query
  const data = useStaticQuery<{
    classicSundays: TixHarpaDateConnection
    music: TixHarpaDateConnection
    theater: TixHarpaDateConnection
    dance: TixHarpaDateConnection
    exhibition: TixHarpaDateConnection
    rockandpop: TixHarpaDateConnection
    conventions: TixHarpaDateConnection
    classicContemporary: TixHarpaDateConnection
    movieconcert: TixHarpaDateConnection
    jazz: TixHarpaDateConnection
    standup: TixHarpaDateConnection
    opera: TixHarpaDateConnection
    icelandicsymphony: TixHarpaDateConnection
    family: TixHarpaDateConnection
    jolatonleikar: TixHarpaDateConnection
    advent: TixHarpaDateConnection
    jol: TixHarpaDateConnection
    aramota: TixHarpaDateConnection
    jazzhatid: TixHarpaDateConnection
    mulinn: TixHarpaDateConnection
    velkominHeim: TixHarpaDateConnection
    upprasin: TixHarpaDateConnection
    tour: TixHarpaDateConnection
    heimssvidid: TixHarpaDateConnection
  }>(graphql`
    query EventsQuery2 {
      classicSundays: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Sígildir sunnudagar/i" } }
      ) {
        ...eventCardFragment
      }
      music: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Tónlist/i" } }
      ) {
        ...eventCardFragment
      }
      family: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Börn og Fjölskyldan/i" } }
      ) {
        ...eventCardFragment
      }
      theater: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Leikhús/i" } }
      ) {
        ...eventCardFragment
      }
      dance: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Dans/i" } }
      ) {
        ...eventCardFragment
      }
      exhibition: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Sýningar/i" } }
      ) {
        ...eventCardFragment
      }
      rockandpop: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Rokk og popp/i" } }
      ) {
        ...eventCardFragment
      }
      conventions: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Ráðstefnur/i" } }
      ) {
        ...eventCardFragment
      }
      classicContemporary: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Sígild og samtímatónlist/i" } }
      ) {
        ...eventCardFragment
      }
      movieconcert: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Bíótónleikar/i" } }
      ) {
        ...eventCardFragment
      }
      jazz: allTixHarpaDate(sort: { fields: startDate }, limit: 50, filter: { categories: { regex: "/Jazz/i" } }) {
        ...eventCardFragment
      }
      standup: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Uppistand/i" } }
      ) {
        ...eventCardFragment
      }
      opera: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Ópera/i" } }
      ) {
        ...eventCardFragment
      }
      icelandicsymphony: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/sinfóníuhljómsveit íslands/i" } }
      ) {
        ...eventCardFragment
      }
      advent: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Aðventa/i" } }
      ) {
        ...eventCardFragment
      }
      jol: allTixHarpaDate(sort: { fields: startDate }, limit: 50, filter: { categories: { regex: "/Jól/i" } }) {
        ...eventCardFragment
      }
      jolatonleikar: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Jólatónleikar/i" } }
      ) {
        ...eventCardFragment
      }
      aramota: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Áramóta/i" } }
      ) {
        ...eventCardFragment
      }
      jazzhatid: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Jazzhátíð/i" } }
      ) {
        ...eventCardFragment
      }
      mulinn: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { categories: { regex: "/Múlinn/i" } }
      ) {
        ...eventCardFragment
      }
      velkominHeim: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { name: { regex: "/Velkomin heim/i" } }
      ) {
        ...eventCardFragment
      }
      tour: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { name: { regex: "/Skoðunarferð/i" } }
      ) {
        ...eventCardFragment
      }
      upprasin: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { name: { regex: "/Upprásin/i" } }
      ) {
        ...eventCardFragment
      }
      heimssvidid: allTixHarpaDate(
        sort: { fields: startDate }
        limit: 50
        filter: { name: { regex: "/Bamberg/i" } }
      ) {
        ...eventCardFragment
      }
    }
  `)

  const { dateFormat } = useLanguage()
  return (
    <div className={cn('relative', className)}>
      {data[selectedCategory] && (
        <ImageSliderWithScrollLock
          className={cn(sliderContainerClass)}
          cards={data[selectedCategory].nodes.map(date => ({
            title: date.name || '',
            image: date.featuredImage?.childImageSharp?.resize?.src || '',
            subtitle: dateFormat(new Date(date.startDate), "dd'.' MMMM '—' p"),
            href: makePath(locale, { type: 'event', slug: date.slug ?? '' }),
          }))}
        />
      )}
    </div>
  )
}

export default EventCategoryList
