import React, { Dispatch, useState, SetStateAction } from 'react'
import { Button, Checkbox, Link, Text } from '..'

export interface ThirdPartyBlockerProps {
  className?: string
  setAllowEmbed: Dispatch<SetStateAction<boolean | undefined>>
  setAllowCurrent: Dispatch<SetStateAction<boolean>>
  termsLink: string
  translate?: (word: string) => string
  locale?: string
}

const Terms = ({ link, locale }: { link: string; locale: string }) => {
  if (locale === 'is') {
    return (
      <Text variant='pAlt'>
        Þetta efni er hýst af þriðja aðila. Með því að birta efnið samþykkir þú
        <Link noStyle to={link}>
          {' '}
          skilmála
        </Link>{' '}
        þeirra
      </Text>
    )
  }
  return (
    <Text variant='pAlt'>
      This content is hosted by a third party. By showing the external content you accept the{' '}
      <Link noStyle to={link}>
        terms and conditions
      </Link>{' '}
      þeirra
    </Text>
  )
}

export const ThirdPartyBlocker = ({
  className = '',
  setAllowCurrent,
  setAllowEmbed,
  termsLink,
  locale = 'is',
  translate = s => s,
}: ThirdPartyBlockerProps) => {
  const [rememberMe, setRememberMe] = useState(false)

  const toggle = () => {
    setRememberMe(!rememberMe)
  }

  const onShowClick = () => {
    if (rememberMe) {
      setAllowEmbed(true)
    }
    setAllowCurrent(true)
  }

  return (
    <div className={className}>
      <div className='px-8 pb-2 text-center text-white align-middle md:px-24 md:pb-6'>
        <Terms locale={locale} link={termsLink} />
      </div>
      <Button size='sm' variant='ghost' className='self-center text-black bg-white' onClick={onShowClick}>
        {translate('show-third-party')}
      </Button>
      <Checkbox
        className='self-center pt-6 text-center text-white md:pt-12'
        checked={rememberMe}
        description={translate('remember-setting')}
        toggle={toggle}
      />
    </div>
  )
}
export default ThirdPartyBlocker
