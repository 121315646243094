import React, { useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { MainLayout } from '@tmp'
import { GetPageQuery } from '@gql-types'
import { withLanguageProvider } from '../context'
import { LocalizedRoute } from '../../utils/routes'
import { PageContent } from '@src/data/mappings/pageMappings'
import Locale from '../../utils/locale'
import { assertDefined } from '@src/utils/helpers'
import { ANNUAL_REPORT_2023 } from '@src/utils/contants'

const PageTemplate = ({ data: { contentfulPage, localizedPages }, location }: PageProps<GetPageQuery>) => {
  contentfulPage = assertDefined(contentfulPage)

  const isAnnualReport = contentfulPage.tag ? true : false
  const localizedUrls: Array<LocalizedRoute> = useMemo(() => {
    return localizedPages.nodes.map(({ node_locale, slug, parentPage }) => ({
      locale: node_locale as Locale,
      route: { type: 'page', slug: slug ?? '', parentSlug: parentPage?.slug ?? undefined },
    }))
  }, [localizedPages])

  return (
    <MainLayout
      seo={contentfulPage?.seo ?? undefined}
      defaultSeoOverwrites={{ title: contentfulPage.pageTitle, ogTitle: contentfulPage.pageTitle }}
      localizedUrls={localizedUrls}
      isAnnualReport={isAnnualReport}
      annualTag={contentfulPage.tag ?? ANNUAL_REPORT_2023}
    >
      <PageContent
        content={contentfulPage.pageContent}
        location={location}
        annualTag={contentfulPage.tag ?? ANNUAL_REPORT_2023}
        isAnnualReport={isAnnualReport}
      />
    </MainLayout>
  )
}

export default withLanguageProvider(PageTemplate)

export const pageQuery = graphql`
  query getPage($contentfulId: String, $locale: String) {
    contentfulPage: contentfulPage(contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale }) {
      ...pageFragment
    }

    localizedPages: allContentfulPage(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        slug
        parentPage {
          slug
        }
      }
    }
  }
`
