import React from 'react'
import { Helmet } from 'react-helmet'
import ogDefault from '@img/ogDefault.jpeg'
import { useLocation } from '@reach/router'

export interface BaseLayoutProps {
  seo?: SeoType | null
  defaultSeoOverwrites?: SeoType | null
}

type SeoType = {
  title?: string | null
  description?: string | null
  keywords?: string | null
  ogtitle?: string | null
  ogtype?: string | null
  ogimage?: { file: { url: string } } | null
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children, seo, defaultSeoOverwrites = {} }) => {
  // Spreading won't work here cause if the value is null then it will overwrite the default value
  const seoObj = {
    title: seo?.title || defaultSeoOverwrites?.title || 'Harpa',
    description:
      seo?.description ||
      defaultSeoOverwrites?.description ||
      'Harpa er eitt helsta kennileiti Reykjavíkur og er miðstöð menningar og mannlífs í hjarta miðborgarinnar, þar sem hún stendur tignarleg við hafnarbakkann.',
    keywords: seo?.keywords || defaultSeoOverwrites?.keywords || 'Harpa',
    ogTitle: seo?.ogtitle || defaultSeoOverwrites?.ogtitle || 'Harpa tónlistar- og ráðstefnuhús',
    url: useLocation().href,
    ogType: seo?.ogtype || defaultSeoOverwrites?.ogtype || 'website',
    ogImage: 'https:' + seo?.ogimage?.file?.url || defaultSeoOverwrites?.ogimage?.file?.url || ogDefault,
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{seoObj.title}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta charSet='utf-8' />
        <link data-react-helmet='true' rel='icon' href="/favicon.ico" />
        <meta name='description' property='og:description' content={seoObj.description.slice(0, 500)} />
        <meta property='og:title' content={seoObj?.ogTitle} />
        <meta property='og:url' content={seoObj?.url} />
        <meta property='og:type' content={seoObj?.ogType} />
        <meta property='og:image' content={seoObj?.ogImage} />
        <meta name='author' content='Harpa'></meta>
        {/* Values taken from current Harpa.is web */}
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta name='keywords' content={seoObj?.keywords} />
        <html lang='is-IS' />
      </Helmet>
      {children}
    </>
  )
}

export default BaseLayout
