import { useEffect, useRef, RefObject } from 'react'

const THRESHOLD = 5

const useVerticalLockScroll = (ref: RefObject<HTMLDivElement>) => {
  const initialTouches = useRef({ firstClientY: 0, firstClientX: 0 })

  useEffect(() => {
    const onTouch = (event: TouchEvent) => {
      initialTouches.current = {
        firstClientY: event.touches?.[0]?.clientY,
        firstClientX: event.touches?.[0]?.clientX,
      }
    }

    const onSwipe = (event: TouchEvent) => {
      if (!ref.current || !ref.current.contains(event.target as Node)) {
        return
      }

      const minValue = THRESHOLD
      const clientX = event.touches?.[0]?.clientX - initialTouches.current.firstClientX

      // Disable vertical scrolling when horizontal swipe is detected.
      if (Math.abs(clientX ?? 0) > minValue && event.cancelable) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }
    }

    window.addEventListener('touchstart', onTouch)
    window.addEventListener('touchmove', onSwipe, { passive: false })

    return () => {
      window.removeEventListener('touchstart', onTouch)
      window.removeEventListener('touchmove', onSwipe)
    }
  }, [])
}

export { useVerticalLockScroll }
