import { useEffect } from 'react'

const useLockBodyScroll = (condition = true) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (condition) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [condition])
}

export { useLockBodyScroll }
