import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Video from '../Video'
import Text from '../Text'

export interface FootageProps {
  title?: string | null
  videoSrcURL?: string | null
  image: IGatsbyImageData
  className?: string
  autoplay?: boolean
  controls?: boolean
  volume?: number
  muted?: boolean
  loop?: boolean
  locale?: string
  translate?: (word: string) => string
}

export const Footage = ({
  title,
  videoSrcURL,
  image,
  autoplay,
  controls,
  volume,
  muted,
  loop,
  className = '',
}: FootageProps) => {
  if (!videoSrcURL && !image) {
    return null
  }

  return (
    <div className={className}>
      {title && (
        <Text variant='h3' weight='heavy' className='mb-8'>
          {title}
        </Text>
      )}
      <div className='w-full relative'>
        {videoSrcURL ? (
          <div className='w-full relative' style={{ aspectRatio: '16/9' }}>
            <Video
              videoSrcURL={videoSrcURL}
              className='absolute top-0 left-0 w-full h-full'
              autoplay={autoplay}
              controls={controls}
              volume={volume}
              muted={muted}
              loop={loop}
            />
          </div>
        ) : (
          <GatsbyImage alt={title ?? ''} image={image} />
        )}
      </div>
    </div>
  )
}

export default Footage
