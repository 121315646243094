import React from 'react'
import cn from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Text, Video } from '@cmp'
import { HyphenText } from '@connected'

export interface HeroHeaderProps {
  /**
   * H1 title intersecting with image on the right.
   */
  title?: string
  intro?: string
  image?: IGatsbyImageData
  className?: string
  annualTag?: string
  isAnnualReport?: boolean
}

export const HeroHeader = ({
  title,
  image,
  className,
  intro,
  annualTag,
  isAnnualReport = false,
}: HeroHeaderProps) => {
  const isFront = annualTag?.includes('FRONT')

  if (isFront && image) {
    return <NewImageHero title={title} className={className} intro={intro} image={image} />
  }
  if (isFront && !image) {
    return <VideoHero title={title} className={className} intro={intro} />
  }
  if (!isFront && image) {
    return <ImageHero title={title} className={className} intro={intro} image={image} />
  }
  if (intro) {
    return <QuashiHero title={title} className={className} intro={intro} isAnnualReport={isAnnualReport} />
  }

  return title ? (
    <div className={cn('lg:py-20', className)}>
      <HyphenText as='h1' variant='h1' weight='heavy' text={title} />
    </div>
  ) : null
}

const QuashiHero = ({ title, className, intro, isAnnualReport }: Omit<HeroHeaderProps, 'image'>) => (
  <div
    className={cn(
      'flex flex-col min-h-screen bg-left-top bg-no-repeat pt-header -mt-header',
      {
        'bg-h-full bg-quasi-alt-2': !isAnnualReport,
      },
      { 'bg-h-full bg-annualquasi': isAnnualReport },
    )}
  >
    <div className={cn('lg:pt-20 pb-20 flex flex-col flex-grow', className)}>
      {title && <HyphenText as='h1' variant='h1' className='my-8 lg:mt-0' text={title} />}
      <div className='flex items-center flex-grow lg:mx-auto lg:w-8/12'>
        <Text variant='intro'>{intro}</Text>
      </div>
    </div>
  </div>
)

const VideoHero = ({ title, className, intro }: Omit<HeroHeaderProps, 'image'>) => (
  <div className='flex flex-col min-h-screen bg-left-top bg-no-repeat -mt-header bg-h-full'>
    <div className={cn('lg:pt-[162px] pt-[82px] pb-20 flex flex-col flex-grow z-1', className)}>
      {title && <HyphenText as='h1' variant='h1' className='my-8 lg:mt-0' text={title} />}
      <div className='flex items-center flex-grow lg:mx-auto lg:w-8/12'>
        <Text variant='intro'>{intro}</Text>
      </div>
    </div>
    <Video
      className='absolute w-full h-full'
      videoSrcURL='/arsskyrsla-loop-harpa.mp4'
      autoplay
      loop
      muted
      hideWhileLoad
    />
  </div>
)

const ImageHero = ({ title, image, className, intro }: HeroHeaderProps) => (
  <div className={cn('lg:flex mb-10 lg:mb-0 lg:py-20', className)}>
    {image && (
      <div className='hidden w-full lg:block lg:w-7/12'>
        <GatsbyImage alt='' className='w-full lg:-ml-16 window-clip' image={image} />
      </div>
    )}
    <div className='flex flex-col lg:pl-10 lg:w-5/12'>
      {title && <HyphenText text={title} as='h1' variant='h2' weight='heavy' className='mt-5' />}
      {image && <GatsbyImage alt='' className='w-full mt-5 lg:hidden window-clip' image={image} />}
      {intro && (
        <div className='flex items-center flex-grow'>
          <Text variant='intro' className='mt-8'>
            {intro}
          </Text>
        </div>
      )}
    </div>
  </div>
)

const NewImageHero = ({ title, image, className, intro }: HeroHeaderProps) => {
  // split "Árs- og sjálf­bærni­skýrsla 2022" into "Árs- og" and "sjálf­bærni­skýrsla 2022"
  // to make it look like in design...
  const part1 = `${title?.split(' og ')[0]} og`
  const part2 = title?.split(' og ')[1]

  return (
    <>
      <div className={cn('relative flex lg:mb-0 lg:py-20 min-h-[100svh] border-b', className)}>
        {image && (
          <div className='absolute -top-23 left-0 right-0 bottom-0 w-full'>
            <GatsbyImage
              alt=''
              image={image}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              imgStyle={{
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
                height: '400px',
                background:
                  'linear-gradient(to top, transparent, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 1))',
              }}
            />
          </div>
        )}
        <div className='absolute bottom-28 left-0 right-0 gutter lg:pl-10 max-w-[800px] lg:max-w-[1000px] pt-4'>
          {part1 && <HyphenText text={part1} as='h1' variant='largeHeader' weight='heavy' />}
          {part2 && <HyphenText text={part2} as='h1' variant='largeHeader' weight='heavy' />}
        </div>
      </div>
      {intro && (
        <div className='gutter'>
          <div className='w-full py-11 md:pt-24 pb-0 md:pb-12 mx-auto lg:w-8/12'>
            <Text variant='intro'>{intro}</Text>
          </div>
        </div>
      )}
    </>
  )
}

export default HeroHeader
