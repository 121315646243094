import React from 'react'
import { navigate } from 'gatsby-link'
import cn from 'classnames'
import { get } from 'lodash'
import { Text, Button, RichTextProps, RichText } from '@cmp'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

export interface FeatureProps {
  title: string
  featureImage: IGatsbyImageData
  squareImage?: boolean
  description?: string
  richText?: RichTextProps['document']
  containImage?: boolean | null
  imageDescription?: string
  secondaryImageDescription?: string
  button?: {
    label: string
    link?: string
  }
  className?: string
  variant?: 'primary' | 'secondary'
}

export const Feature = ({
  title,
  featureImage,
  squareImage,
  containImage = false,
  description = '',
  richText,
  button,
  imageDescription,
  secondaryImageDescription,
  variant = 'primary',
  className = '',
}: FeatureProps) => {
  const heading = variant === 'secondary' ? 'h2' : 'h3'
  return (
    <div
      className={cn(
        'w-full relative up-one-pixel grid grid-cols-1 md:grid-cols-2 select-none border-t pt-4 mt-12 md:mt-24',
        className,
      )}
    >
      <div className='flex flex-col w-full md:w-3/4'>
        <Text
          variant={heading}
          as={heading}
          weight='heavy'
          className={cn('mb-12 md:mb-24', { 'lg:mt-20': variant === 'secondary' })}
        >
          {title}
        </Text>
        {richText?.raw ? (
          <RichText document={richText} className='mb-8' textStyles={{ weight: 'light' }} />
        ) : (
          <Text weight='light' className='mb-8'>
            {description}
          </Text>
        )}
        {button && button.label && (
          <Button
            onClick={() => navigate(get(button, 'link', get(button, 'link', '/')))}
            variant='ghost'
            className={cn('w-full mb-12 h-10px', { 'md:w-3/4': variant === 'primary' })}
            icon
          >
            {button.label}
          </Button>
        )}
      </div>
      <div>
        {squareImage && featureImage && (
          <div
            className='w-full relative md:mt-32'
            style={{
              aspectRatio: '1',
            }}
          >
            <GatsbyImage
              alt=''
              image={featureImage}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              imgStyle={{
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            />
          </div>
        )}
        {!squareImage && featureImage && (
          <div className='w-full relative md:mt-32'>
            <GatsbyImage objectFit={containImage ? 'contain' : 'cover'} alt='' image={featureImage} />
          </div>
        )}
        {imageDescription && (
          <div className='mt-1 flex flex-wrap gap-1'>
            <Text variant='sm'>{imageDescription}</Text>
            {secondaryImageDescription && (
              <Text variant='sm' weight='light'>
                {secondaryImageDescription}
              </Text>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default Feature
