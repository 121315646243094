import React, { ReactNode } from 'react'
import { useDisclosureState, Disclosure, DisclosureContent } from 'reakit/Disclosure'
import { motion } from 'framer-motion'
import cn from 'classnames'
import Icon from '../Icon'
import Text from '../Text'

export interface CollapseProps {
  /**
   * button text
   */
  title: ReactNode
  /**
   * collapsible content
   */
  children: ReactNode
  /**
   * expanded by default
   */
  initialVisibility?: boolean
  /**
   * className for title button
   */
  className?: string
  /**
   * hide arrow indicator
   */
  hideArrow?: boolean
  icon?: 'Arrow' | 'Plus' | 'PlusWhite'
  secondaryTitle?: string
  secondaryTitleWeight?: "normal" | "bold" | "light" | "heavy" | undefined
  secondaryTitleVar?: "base" | "h1" | "h2" | "h3" | "h4" | "p" | "medium" | "pAlt" | "h1Alt" | "h1Alt2" | "h3Alt" | "intro" | "sm" | "xs" | undefined
  iconColor?: 'black' | 'white'
}

export const Collapse = ({ title, children, initialVisibility, className, hideArrow, icon = 'Arrow', iconColor = 'white', secondaryTitle, secondaryTitleWeight = 'normal', secondaryTitleVar = 'pAlt' }: CollapseProps) => {
  const disclosure = useDisclosureState({ visible: initialVisibility, animated: true })
  const contentVariants = {
    visible: { opacity: 1, height: 'auto', transition: { type: 'tween', ease: 'easeOut' } },
    hidden: { opacity: 0, height: 0, transition: { type: 'tween', ease: 'easeIn' } },
  }
  const arrowVariants = {
    visible: { rotate: -45, transition: { type: 'tween' } },
    hidden: { rotate: 45, transition: { type: 'tween' } },
  }
  const plusVariants = {
    visible: { opacity: 1, transition: { type: 'tween' } },
    hidden: { opacity: 0, transition: { type: 'tween' } },
  }

  return (
    <>
      <Disclosure
        {...disclosure}
        className={cn('w-full text-left focus:outline-none flex items-center', className)}
      >
        {!secondaryTitle &&
          <Text>{title}</Text>
        }
        {secondaryTitle &&
          <>
            <Text as='div' variant='h3' className='pt-1'>{title} </Text> <Text as='span' variant={secondaryTitleVar} weight={secondaryTitleWeight} className='pl-2 pt-1'>{secondaryTitle}</Text>
          </>
        }
        {!hideArrow && icon === 'Arrow' && (
          <motion.span
            animate={disclosure.visible ? 'visible' : 'hidden'}
            variants={arrowVariants}
            className='inline-block ml-auto'
          >
            <Icon name='Arrow' className='w-3 h-auto' />
          </motion.span>
        )}
         {icon === 'Plus' && (
          <div className='inline-block ml-auto relative pr-3 -mt-1'>
            <motion.span
              animate={!disclosure.visible ? 'visible' : 'hidden'}
              variants={plusVariants}
              className='absolute inline-block ml-auto -top-1'
            >
              <Icon name='Plus' className='w-3 h-auto' color={iconColor}/>
            </motion.span>
              <motion.span
              animate={disclosure.visible ? 'visible' : 'hidden'}
              variants={plusVariants}
              className='absolute inline-block ml-auto top-0'
            >
              <Icon name='Minus' className='w-3 h-auto' color={iconColor}/>
            </motion.span>
          </div>
        )}
        {icon === 'PlusWhite' && (
          <div className='inline-block ml-auto relative pr-3 -mt-1'>
            <motion.span
              animate={!disclosure.visible ? 'visible' : 'hidden'}
              variants={plusVariants}
              className='absolute inline-block ml-auto -top-1'
            >
              <Icon name='PlusWhite' className='w-3 h-auto' color={iconColor}/>
            </motion.span>
              <motion.span
              animate={disclosure.visible ? 'visible' : 'hidden'}
              variants={plusVariants}
              className='absolute inline-block ml-auto top-0'
            >
              <Icon name='MinusWhite' className='w-3 h-auto' color={iconColor}/>
            </motion.span>
          </div>
        )}
      </Disclosure>
      <DisclosureContent
        as={motion.div}
        initial='hidden'
        animate={disclosure.visible ? 'visible' : 'hidden'}
        variants={contentVariants}
        onAnimationComplete={() => {
          disclosure.stopAnimation()
        }}
        {...disclosure}
      >
        {children}
      </DisclosureContent>
    </>
  )
}

export default Collapse
