import { NewsArticlePage, NewsArticlePageProps } from '@cmp'
// @ts-expect-error
import { ContentfulNewsArticle } from '@gqlTypes'
import { useLanguage } from '@src/templates'

type NewsArticleType = {
  component: () => React.FunctionComponent<NewsArticlePageProps>
  dataToProps: (data: ContentfulNewsArticle) => NewsArticlePageProps
}

export default {
  ContentfulNewsArticle: {
    component: () => NewsArticlePage,
    dataToProps(data: ContentfulNewsArticle) {
      const { dateFormat, locale, t } = useLanguage()
      return {
        ...data,
        photo: data.photo?.gatsbyImageData,
        introText: data.introText?.introText,
        gallery: data.myndagallery?.image,
        footage: data.footage,
        createdAt: dateFormat(new Date(data?.overwriteDate || data.createdAt), `do MMMM y`),
        locale,
        translate: t,
      }
    },
  } as NewsArticleType,
} as {
  [key: string]: {
    component: any
    dataToProps: any
  }
}
