import React from 'react'
import cn from 'classnames'
import { Text } from '@cmp'
import { HyphenText } from '@connected'
import { ColumnLayout } from '@src/utils'

export interface OverviewBarProps {
  labels: string[]
  title?: string
  className?: string
  colLayout?: ColumnLayout
  hugeHeading?: Boolean
}

export const OverviewBar = ({ title, labels, className, colLayout, hugeHeading = true }: OverviewBarProps) => {
  return (
    <>
      {title && (
        <div className={cn({'pt-36 md:mb-24': hugeHeading }, 'mt-10 gutter md:pt-0')}>
          <HyphenText text={title} as='h1' variant={hugeHeading ? 'h1' : 'h2'} weight='heavy' />
        </div>
      )}
      <div
        className={cn(
          'border-t border-current grid select-none pt-36 md:pt-0 grid-flow-col auto-cols-fr',
          className,
          colLayout,
        )}
      >
        {labels.map((label, idx) => (
          <div className='flex' key={idx}>
            <span
              className={cn('h-8', {
                'md:border-l md:border-current': idx > 0,
              })}
            ></span>
            <Text as='h3' variant='h3' weight='heavy' className={cn('my-4 px-4', { 'gutter-l': idx === 0 })}>
              {label}
            </Text>
          </div>
        ))}
      </div>
    </>
  )
}
export default OverviewBar
