import React from 'react'
import { Chart as ChartJS, ChartOptions, Legend, ArcElement, ArcOptions } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartDeferred from 'chartjs-plugin-deferred'
import { ChartsProps } from '@src/utils/types'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { chartColors, chartOptions, useChartResize } from '../shared'

ChartJS.register(ArcElement, Legend, ChartDeferred, ChartDataLabels)

const labels: (string | string[])[] = [
  'Rekstrarframlag',
  'Viðbótarrekstrarframlag',
  'Viðburðarhald',
  'Leigutekjur',
  'Rekstrarleyfissamningur',
  'Aðrar tekjur',
]

export const mockData = {
  labels,
  datasets: [
    {
      data: [100, 123, 221, 40, 22, 33],
      backgroundColor: chartColors,
      borderColor: '#FFFFFF',
      borderWidth: 2,
    },
  ],
}

export const ChartJsPie = ({ data, id, colors }: ChartsProps) => {
  const options: ChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      datalabels: {
        display: true,
        color: '#160D13',
        formatter: value => {
          return `${Math.round(parseInt(value, 10))}%`
        },
        font: {
          size: 15,
          family: 'DIN Next',
          weight: 'lighter',
        },
        anchor: 'end',
        align: 'end',
      },
      legend: {
        ...(chartOptions.plugins?.legend && chartOptions.plugins?.legend),
        title: { display: true, padding: 32 }, // hack to make space between bottom legend and chart
      },
    },
    layout: {
      padding: {
        top: 30,
        left: 20,
        right: 20,
      },
    },
    scales: undefined,
  }

  const _colors = colors ?? chartColors

  useChartResize({ ChartJS, id: id ?? '' })

  if (!data) return null

  const total =
    data?.[0]?.data?.reduce((acc, cur) => {
      return acc + parseInt(cur?.value, 10)
    }, 0) ?? 0

  const chartData = {
    labels: data?.[0]?.data?.map(item => item.label),
    datasets: [
      {
        data: data?.[0]?.data?.map(item => (parseInt(item.value, 10) / total) * 100),
        backgroundColor: _colors,
        borderColor: '#FFFFFF',
        borderWidth: 2,
      },
    ],
  }

  return (
    <div className='min-h-[600px] md:min-h-[700px] w-full items-center flex px-4 2xl:min-w-0 lg:max-w-[79%]'>
      <Pie id={id ?? ''} options={options as ArcOptions} data={chartData} />
    </div>
  )
}

export default ChartJsPie
