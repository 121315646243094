import { useEffect, useState, useRef } from 'react'

const useIsSwipe = () => {
  const touchStart = useRef<null | number>(null)
  const touchEnd = useRef<null | number>(null)

  const [isSwipe, setIsSwipe] = useState(false)

  const minSwipeDistance = 50

  useEffect(() => {
    const onTouchStart = (e: TouchEvent) => {
      touchEnd.current = null
      touchStart.current = e.targetTouches[0].clientY
    }

    const onTouchMove = (e: TouchEvent) => {
      touchEnd.current = e.targetTouches[0].clientY
    }

    const onTouchEnd = () => {
      if (!touchStart || !touchEnd) {
        return
      }
      const distance = (touchStart?.current ?? 0) - (touchEnd?.current ?? 0)
      const isUpSwipe = distance > minSwipeDistance
      const isDownSwipe = distance < -minSwipeDistance
      if (isUpSwipe || isDownSwipe) {
        setIsSwipe(true)
      } else {
        setIsSwipe(false)
      }
    }

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)
    window.addEventListener('touchend', onTouchEnd)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
      window.removeEventListener('touchend', onTouchEnd)
    }
  })

  return { isSwipe }
}

export { useIsSwipe }
