import HarpaHorizontal, { ReactComponent as HarpaHorizontalIcon } from './icons/harpa-horizontal.svg'
import HarpaSingle, { ReactComponent as HarpaSingleIcon } from './icons/harpa-single.svg'
import HarpaVertical, { ReactComponent as HarpaVerticalIcon } from './icons/harpa-vertical.svg'
import Search, { ReactComponent as SearchIcon } from './icons/search.svg'
import CurvedArrow, { ReactComponent as CurvedArrowIcon } from './icons/curved-arrow.svg'
import Arrow, { ReactComponent as ArrowIcon } from './icons/arrow.svg'
import GradientArrow, { ReactComponent as GradientArrowIcon } from './icons/gradient-arrow.svg'
import Swirl, { ReactComponent as SwirlIcon } from './icons/swirl.svg'
import Chevron, { ReactComponent as ChevronIcon } from './icons/chevron.svg'
import Union, { ReactComponent as UnionIcon } from './icons/union.svg'
import Plus, { ReactComponent as PlusIcon } from './icons/plus.svg'
import Minus, { ReactComponent as MinusIcon } from './icons/minus.svg'
import CheckMark, { ReactComponent as CheckMarkIcon } from './icons/checkmark.svg'
import CheckMarkBlack, { ReactComponent as CheckMarkBlackIcon } from './icons/checkmark-black.svg'
import PlusWhite, { ReactComponent as PlusWhiteIcon } from './icons/plus-white.svg'
import MinusWhite, { ReactComponent as MinusWhiteIcon } from './icons/minus-white.svg'
import UnionWhite, { ReactComponent as UnionWhiteIcon } from './icons/union-white.svg'

// These bracketed components are the ones Storybook looks for e.g. ArrowIcon
// Gatsby want the one outside the bracket e.g. Arrow
// The string check is because Storybook has the option to fetch the file path outside of the bracket

const Icons = {
  HarpaHorizontal: typeof HarpaHorizontal === 'string' ? HarpaHorizontalIcon : HarpaHorizontal,
  HarpaSingle: typeof HarpaSingle === 'string' ? HarpaSingleIcon : HarpaSingle,
  HarpaVertical: typeof HarpaVertical === 'string' ? HarpaVerticalIcon : HarpaVertical,
  Search: typeof Search === 'string' ? SearchIcon : Search,
  CurvedArrow: typeof CurvedArrow === 'string' ? CurvedArrowIcon : CurvedArrow,
  Arrow: typeof Arrow === 'string' ? ArrowIcon : Arrow,
  GradientArrow: typeof GradientArrow === 'string' ? GradientArrowIcon : GradientArrow,
  Swirl: typeof Swirl === 'string' ? SwirlIcon : Swirl,
  Chevron: typeof Chevron === 'string' ? ChevronIcon : Chevron,
  Union: typeof Union === 'string' ? UnionIcon : Union,
  Plus: typeof Plus === 'string' ? PlusIcon : Plus,
  Minus: typeof Minus === 'string' ? MinusIcon : Minus,
  CheckMark: typeof CheckMark === 'string' ? CheckMarkIcon : CheckMark,
  CheckMarkBlack: typeof CheckMarkBlack === 'string' ? CheckMarkBlackIcon : CheckMarkBlack,
  PlusWhite: typeof PlusWhite === 'string' ? PlusWhiteIcon : PlusWhite,
  MinusWhite: typeof MinusWhite === 'string' ? MinusWhiteIcon : MinusWhite,
  UnionWhite: typeof UnionWhite === 'string' ? UnionWhiteIcon : UnionWhite,
}

export default Icons
