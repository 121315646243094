import React from 'react'
import {
  Chart as ChartJS,
  ChartOptions,
  CategoryScale,
  LinearScale,
  LineElement,
  Legend,
  LineOptions,
  PointElement,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import ChartDeferred from 'chartjs-plugin-deferred'
import { ChartsProps } from '@src/utils/types'
import { LARGE_FONT, chartColors, chartOptions, useChartResize } from '../shared'

ChartJS.register(CategoryScale, LinearScale, LineElement, Legend, PointElement, ChartDeferred)

const sharedProps = {
  pointStyle: false,
  borderWidth: 8,
}

export const ChartJsLines = ({ data, id, colors }: ChartsProps) => {
  const options: ChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      datalabels: {
        display: false,
      },
      legend: {
        position: 'bottom',
        align: 'center',
        title: { display: true, padding: 8 }, // hack to make space between bottom legend and chart
        labels: {
          padding: 20,
          boxHeight: 0,
          boxWidth: 21,
          font: {
            size: LARGE_FONT,
            family: 'DIN Next',
            weight: 'lighter',
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: '#160D13',
        },
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 10,
          font: {
            size: LARGE_FONT,
            family: 'DIN Next',
            weight: 'lighter',
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: true,
        },
        angleLines: {
          display: false,
          lineWidth: 0,
          color: 'transparent',
        },
        ticks: {
          padding: 10,
          font: {
            size: LARGE_FONT,
            family: 'DIN Next',
            weight: 'lighter',
          },
        },
      },
    },
  }

  const _colors = colors ?? chartColors

  useChartResize({ ChartJS, id: id ?? '' })

  if (!data) return null

  const chartLabels = data?.[0]?.data?.map(item => item?.label ?? '')

  const yearlyValues: { [key: string]: string[] } = {}

  for (const item of data) {
    for (const entry of item.data) {
      const year = entry.label
      const value = entry.value

      if (year) {
        if (year in yearlyValues) {
          yearlyValues[year].push(value)
        } else {
          yearlyValues[year] = [value]
        }
      }
    }
  }

  const chartData = {
    labels: chartLabels,
    datasets:
      data?.map((item, index) => ({
        label: item.title,
        data: item.data.map(entry => entry.value),
        borderColor: _colors[index],
        backgroundColor: _colors[index],
        ...sharedProps,
      })) ?? [],
  }

  return (
    <div className='h-[500px] md:h-[700px] w-full items-center flex px-4 max-w-full lg:max-w-[79%]'>
      <Line id={id ?? ''} options={options as LineOptions} data={chartData as any} />
    </div>
  )
}

export default ChartJsLines
