import React from 'react'
import { Text, Link } from '@cmp'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

export interface BookingInfoProps {
  title: string | undefined | null
  className?: string
  description: string | undefined | null
  departments: any[] | null | undefined
}

export const BookingInfo = ({ title, description, departments, className = '' }: BookingInfoProps) => {
  return (
    <div className={cn('m-auto', className)}>
      <Text variant='h3' weight='heavy' className='mb-8'>
        {title}
      </Text>
      <Text className='my-8'>{description}</Text>
      {departments?.length && (
        <div className='-mx-2.5 flex flex-wrap md:flex-nowrap'>
          {departments?.map((department, idx) => (
            <div className='px-2.5 w-6/12 md:w-3/8' key={idx}>
              {department?.photo?.gatsbyImageData && (
                <GatsbyImage className='w-full mb-4' alt='' image={department?.photo?.gatsbyImageData} />
              )}
              <Text>{department?.name}</Text>
              <div className='mb-4'>{department?.role ? <Text weight='light'>{department.role}</Text> : null}</div>
              <div>
                {department?.email && (
                  <Text>
                    <Link to={`mailto:${department?.email}`}>{department?.email}</Link>
                  </Text>
                )}
                {department?.phoneNumber && (
                  <Text>
                    <Link to={`tel:+354-${department?.phoneNumber}`}>{department?.phoneNumber}</Link>
                  </Text>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default BookingInfo
