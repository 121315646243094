import { LinkFragmentFragment } from '@gql-types'
import { makePath } from './routes'
import Locale from './locale'

export const objectToPath = (
  obj: string | LinkFragmentFragment | LinkFragmentFragment['internalLink'],
): string => {
  if (obj) {
    if (typeof obj === 'string') return obj

    switch (obj.__typename) {
      case 'ContentfulLink':
        return objectToPath(obj.internalLink ?? obj.externalLink)
      case 'ContentfulNewsArticle': {
        return makePath(obj.node_locale as Locale, {
          type: 'newsItem',
          slug: obj.slug ?? '',
        })
      }
      case 'ContentfulPage':
        return makePath(obj.node_locale as Locale, {
          type: 'page',
          slug: obj.slug ?? '',
          parentSlug: obj.parentPage?.slug ?? undefined,
        })
      case 'ContentfulVenuePage':
        return makePath(obj.node_locale as Locale, {
          type: 'venue',
          slug: obj.slug ?? '',
          parentSlug: obj.parentPage?.slug ?? undefined,
        })
    }
  }

  return '/?'
}
