import React, { useEffect, useState } from 'react'
import { Text, Link } from '@cmp'
import { useLanguage } from '@tmp'
import { HyphenText } from '@connected'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'
export interface StaffGridProps {
  className?: string
  title?: string
  staffMembers: StaffMember[]
  isStaffPage: boolean
}

type StaffMember = {
  name: string
  role: string
  department?: string
  email?: string
  phone?: string
  photo: IGatsbyImageData
}
const StaffMember = ({ staffMember, className }: { staffMember: StaffMember; className: string }) => {
  const { name, role, email, department, phone = '', photo } = staffMember
  return (
    <div className={className}>
      {photo && <GatsbyImage className='w-full mb-4' alt='' image={photo} />}
      <div className='flex flex-row flex-wrap mb-4'>
        <Text className='pr-3'>{name}</Text>
        <Text className='pr-3' weight='light'>
          {role}
        </Text>
        <Text weight='light'>{department}</Text>
      </div>
      <>
        <Text weight='light'>{email}</Text>
        <Text>{phone}</Text>
      </>
    </div>
  )
}

export const StaffGrid = ({ title, isStaffPage, staffMembers = [] }: StaffGridProps) => {
  const { t } = useLanguage()
  const [selectedFilter, setSelectedFilter] = useState<string>(t('all'))
  const [filteredStaffMembers, setFilteredStaffMembers] = useState<StaffGridProps['staffMembers']>(staffMembers)
  const filters = [t('all'), ...new Set(staffMembers.filter(sm => !!sm.department).map(sm => sm.department ?? ''))]

  useEffect(() => {
    if (selectedFilter === t('all')) {
      setFilteredStaffMembers(staffMembers)
    } else {
      setFilteredStaffMembers(staffMembers.filter(sm => sm.department === selectedFilter))
    }
  }, [selectedFilter])

  return (
    <div className={cn('gutter', { 'border-t mt-20': !isStaffPage })}>
      {isStaffPage ? (
        <div className='pt-24 pb-20 md:pt-20'>
          <HyphenText text={title ?? ''} as='h1' variant='h1' weight='heavy' />
        </div>
      ) : (
        <HyphenText text={title ?? ''} as='h3' variant='h3' className='pt-6 pb-8' weight='heavy' />
      )}
      {filters.length > 1 && isStaffPage && (
        <div className='mb-10'>
          {filters.map(filter => (
            <button
              key={filter}
              className={cn('whitespace-nowrap  py-2 px-3 transition-shadow focus:outline-none', {
                'ring-1 ring-opacity-70 ring-current font-bold': filter === selectedFilter,
              })}
              onClick={() => setSelectedFilter(filter)}
            >
              {/* this is used to fill up width with a bold text so the content wont just when active element gets bolded */}
              <span className='invisible block h-0 font-bold'>{filter}</span>
              {filter}
            </button>
          ))}
        </div>
      )}
      <div className={cn('flex flex-wrap -mx-2.5')}>
        {filteredStaffMembers.map(({ name, role, department, email, phone, photo }, idx) => (
          <StaffMember
            key={idx}
            className={cn({ 'w-full ': !isStaffPage }, 'px-2.5 md:w-4/12 lg:w-4/12 pb-16', {
              'md:w-4/12 w-6/12 lg:w-2/10 2xl:w-2/10': isStaffPage,
            })}
            staffMember={{ name, role, department, email, phone, photo }}
          />
        ))}
      </div>
    </div>
  )
}

export default StaffGrid
