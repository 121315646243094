import React from 'react'
import { ChartsProps } from '@src/utils/types'
import { ChartJsPie } from './ChartJsPie'
import { ChartJsBars } from './ChartJsBars'
import { ChartJsLines } from './ChartJsLines'
import Text from '../Text'
import { chartColors, oldChartColors } from './shared'

export const ChartsJs = ({ title = '', type, data, oldColors }: ChartsProps) => {
  if (!data) return null

  const usedChartColors = oldColors ? oldChartColors : chartColors

  let chart = null

  switch (type) {
    case 'bar':
      chart = <ChartJsBars id={`chart-${title}`} data={data} title={title} colors={usedChartColors} />
      break
    case 'line':
      chart = <ChartJsLines id={`chart-${title}`} data={data} title={title} colors={usedChartColors} />
      break
    case 'pie':
      chart = <ChartJsPie id={`chart-${title}`} data={data} title={title} colors={usedChartColors} />
      break
    default:
      break
  }

  return (
    <div className='pb-32 pt-8'>
      <div className='gutter'>
        <div className='w-full pt-20 m-auto lg:w-8/12'>
          <Text variant='h3' className='mb-8'>
            {title}
          </Text>
        </div>
      </div>
      <div className='max-w-[100vw] overflow-x-scroll no-scrollbar overflow-hidden pl-0 w-full lg:w-[calc(100vw_-_17%)] lg:pl-[17%]'>
        {chart}
      </div>
    </div>
  )
}

export default ChartsJs
