import { CustomWindow } from '../utils/types'
declare let window: CustomWindow

const useCookieBanner = () => {
  const openSettings = () => {
    if (window?.cookiehub) {
      window?.cookiehub?.openSettings()
    }
  }

  const loadCookieBanner = (code: string) => {
    if (window.cookiehub) {
      window.cookiehub.load({
        language: code,
        cookie: {
          sameSite: 'Lax',
          secure: true,
        },
      })
    }
  }

  const hasAnswered = () => {
    if (window?.cookiehub) {
      return window.cookiehub.hasAnswered()
    } else {
      return false
    }
  }

  const setBannerLanguage = (code = 'en') => {
    const container = document?.getElementsByClassName('ch2')?.[0]
    const prompted = hasAnswered()
    if (container && !prompted) {
      container.remove()
    }
    if (!prompted) {
      loadCookieBanner(code)
    }
  }

  return { loadCookieBanner, setBannerLanguage, openSettings }
}

export { useCookieBanner }
