import cn from 'classnames'
import React, { useState } from 'react'
import { useLocalStorage } from 'react-use'
import ReactPlayer from 'react-player/lazy'
import { ALLOW_EMBEDDED_THIRD_PARTY } from '@src/utils/contants'
import ThirdPartyBlocker from '../ThirdPartyBlocker'
require('./Video.css')

export interface VideoProps {
  videoSrcURL?: string | null
  autoplay?: boolean
  controls?: boolean
  volume?: number
  muted?: boolean
  width?: string
  loop?: boolean
  height?: string
  className?: string
  hideWhileLoad?: boolean
  locale?: string
  translate?: (word: string) => string
}

export const Video = ({
  videoSrcURL,
  autoplay,
  volume = 0,
  muted,
  loop,
  controls,
  width = '100%',
  height = '100%',
  className = '',
  hideWhileLoad = false,
  locale = 'is',
  translate,
}: VideoProps) => {
  const [playing, setPlaying] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [allowEmbed, setAllowEmbed] = useLocalStorage(ALLOW_EMBEDDED_THIRD_PARTY, false)

  if (!videoSrcURL) {
    return null
  }
  const isYoutubeVideo = videoSrcURL.includes('youtube')

  if (!isYoutubeVideo || allowEmbed || showVideo) {
    return (
      <div
        className={cn(className, 'transition duration-1000', {
          'opacity-0': !playing && hideWhileLoad,
        })}
      >
        <ReactPlayer
          className='react-player'
          config={{ vimeo: { playerOptions: { dnt: true } } }}
          playing={autoplay}
          url={videoSrcURL}
          volume={volume}
          muted={muted}
          width={width}
          height={height}
          loop={loop}
          controls={controls}
          playsinline
          onReady={() => setPlaying(true)}
        />
      </div>
    )
  }

  return (
    <div className=''>
      <ThirdPartyBlocker
        className='absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-dark-400'
        setAllowCurrent={setShowVideo}
        setAllowEmbed={setAllowEmbed}
        termsLink='https://www.youtube.com/t/terms'
        locale={locale}
        translate={translate}
      />
    </div>
  )
}

export default Video
