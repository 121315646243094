import React from 'react'
import { Button, Text } from '@cmp'
import cn from 'classnames'
import { Maybe, AssetFragmentFragment } from '@gql-types'
import Hyphen from '../Hyphen'

export interface FileTableProps {
  title?: string | null
  buttonLabel: string
  className?: string
  files?: Maybe<AssetFragmentFragment>[] | null
}

export const FileTable = ({ title, buttonLabel, files, className = '' }: FileTableProps) => {
  if (!files?.length) {
    return null
  }

  return (
    <div className={className}>
      <Text variant='h3' className='mb-8' weight='heavy'>
        {title}
      </Text>
      {files?.map((file, index) => (
        <div
          className={cn('flex border-dark-300 border-t border-l border-r', {
            'border-b': index === files.length - 1,
          })}
          key={index}
        >
          <span className='px-6 py-4'>
            <Hyphen>{file?.title ?? ''}</Hyphen>
          </span>
          <span className='flex items-center flex-shrink-0 px-6 ml-auto border-l border-dark-300'>
            {file?.file?.url && (
              <Button size='sm' href={file?.file?.url}>
                {buttonLabel}
              </Button>
            )}
          </span>
        </div>
      ))}
    </div>
  )
}

export default FileTable
