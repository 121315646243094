import React, { ChangeEvent, FocusEvent, forwardRef } from 'react'
import cn from 'classnames'
import { Icon } from '@cmp'
import { IconType } from '../Icon/Icon'

export interface InputProps {
  containerClassName?: string
  disabled?: boolean
  leadingIconName?: IconType
  placeholder?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit?: () => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  value: string
  tabIndex?: number
  type?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      containerClassName,
      leadingIconName,
      placeholder = '',
      value,
      onSubmit,
      onChange,
      onFocus,
      onBlur,
      tabIndex,
      type = 'text',
      disabled = false,
    },
    ref,
  ) => (
    <div className={cn('w-full border flex flex-row items-center ', containerClassName)}>
      {leadingIconName ? <Icon name={leadingIconName} className='w-6 h-auto mx-2' /> : null}
      <input
        ref={ref}
        className='w-full p-2 placeholder-current bg-transparent focus:outline-none'
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        tabIndex={tabIndex}
        onKeyUp={e => {
          if (e.key === 'Enter' && onSubmit) {
            onSubmit()
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  ),
)

export default Input
