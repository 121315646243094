import Markdown from 'markdown-to-jsx'
import React, { useState } from 'react'
import { useLocalStorage } from 'react-use'
import { ThirdPartyBlocker } from '..'
require('./EmbeddedContent.css')

export interface EmbeddedContentProps {
  content?: string | null
  storageKey?: string | null
  terms?: string | null
  locale?: string
  translate?: (word: string) => string
}

export const EmbeddedContent = ({ content, storageKey, terms, locale, translate }: EmbeddedContentProps) => {
  if (!content || !storageKey || !terms) {
    return null
  }
  // only approve current content
  const [showCurrent, setShowCurrent] = useState(false)
  // approve current and future content with same key
  const [allowEmbed, setAllowEmbed] = useLocalStorage(storageKey, false)

  if (allowEmbed || showCurrent) {
    return (
      <div className='h-0 pb-[56.25%] relative'>
        <div className='w-full h-full bg-black'>
          <Markdown>{content}</Markdown>
        </div>
      </div>
    )
  }

  return (
    <div className='h-0 pb-[56.25%] relative bg-black'>
      <ThirdPartyBlocker
        className='absolute flex flex-col justify-center w-full h-full'
        setAllowCurrent={setShowCurrent}
        setAllowEmbed={setAllowEmbed}
        termsLink={terms}
        locale={locale}
        translate={translate}
      />
    </div>
  )
}

export default EmbeddedContent
