import React from 'react'
import {
  Chart as ChartJS,
  ChartOptions,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  BarOptions,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import cn from 'classnames'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartDeferred from 'chartjs-plugin-deferred'
import { ChartsProps } from '@src/utils/types'
import { chartColors, chartOptions, splitStringIntoSubarrays, useChartResize } from '../shared'
import Text from '@src/components/Text'
import { useMeasure } from 'react-use'

const BAR_PERCENTAGE = 2
const CATEGORY_PERCENTAGE = 0.5

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartDataLabels, ChartDeferred)

const sharedBarProps = {
  borderWidth: 1,
  barPercentage: BAR_PERCENTAGE,
  categoryPercentage: CATEGORY_PERCENTAGE,
  borderColor: 'transparent',
  hoverBorderColor: 'transparent',
  maxBarThickness: 38,
  barThickness: 32,
}

export const ChartJsBars = ({ data, id, colors }: ChartsProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>()

  const _colors = colors ?? chartColors

  const options: ChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        display: false,
      },
    },
  }

  useChartResize({ ChartJS, id: id ?? '' })

  if (!data) return null

  const chartLabels = data?.map(item => splitStringIntoSubarrays(item?.title ?? '', 20)) ?? []

  const yearlyValues: { [key: string]: string[] } = {}

  for (const item of data) {
    for (const entry of item.data) {
      const year = entry.label
      const value = entry.value

      if (year) {
        if (year in yearlyValues) {
          yearlyValues[year].push(value)
        } else {
          yearlyValues[year] = [value]
        }
      }
    }
  }

  const datasets =
    data?.[0]?.data?.map((values, index) => {
      const key = values.label ?? ''

      return {
        label: values.label,
        grouped: true,
        font: {
          size: 24,
          family: 'DIN Next',
          weight: 'lighter',
        },
        inflateAmount: 0,
        data: yearlyValues?.[key] ?? [],
        backgroundColor: _colors[index],
        stack: `Stack ${index}`,
        ...sharedBarProps,
      }
    }) ?? []

  const chartData = {
    labels: chartLabels,
    datasets,
  }

  const legendData = datasets.map(item => ({
    text: item.label,
    color: item.backgroundColor,
  }))

  return (
    <>
      <div
        className={cn('h-[500px] md:h-[700px] w-full items-center flex px-4 2xl:min-w-0 lg:max-w-[79%]', {
          'min-w-[1200px]': (chartData?.datasets?.[0]?.data ?? []).length > 1,
        })}
      >
        <Bar id={id ?? ''} options={options as BarOptions} data={chartData as any} />
      </div>
      <div
        ref={ref}
        className='left-0 absolute flex flex-wrap justify-center items-center gap-4 gap-y-2 md:gap-6 w-full mt-6 px-2'
      >
        {legendData.map(({ color, text }, index) => {
          return (
            <div key={index} className='flex gap-2 text-dark-400/60 items-center justify-center'>
              <span
                className='h-[7px] w-[21px] inline-block relative bottom-[1px]'
                style={{ backgroundColor: color }}
              />
              <Text variant='base'>{text}</Text>
            </div>
          )
        })}
      </div>
      <div className='invisible pointer-events-none' style={{ height }} />
    </>
  )
}

export default ChartJsBars
