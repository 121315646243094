import React from 'react'
import cn from 'classnames'
import { Text } from '@cmp'
import { ContentfulInfoList, Maybe } from '@gql-types'

export interface PageListProps {
  className?: string
  lists: Maybe<Pick<ContentfulInfoList, 'title' | 'list'>>[] | undefined
}

export const PageList = ({ className, lists }: PageListProps) => {
  return (
    <div className={cn('w-full border-t border-dark-400 select-none gutter', className)}>
      <div className='flex flex-col w-full m-auto lg:w-8/12 md:flex-row'>
        {lists?.map((infoList, idx) => (
          <div className='flex flex-grow' key={idx}>
            <span
              className={cn('h-8', {
                'md:border-l md:border-black': idx > 0,
              })}
            ></span>
            <div className={cn('pt-4', { 'md:px-4': idx > 0 })}>
              <Text as='h3' variant='h3' weight='heavy' className={cn({ 'md:text-black': idx > 0 })}>
                {infoList?.title ?? ''}
              </Text>
              <ul className='pt-6'>
                {infoList?.list?.map((listItem, idx) => {
                  return (
                    <div className='flex' key={idx}>
                      <Text weight='light'>—&nbsp;</Text>
                      <Text weight='light' key={listItem}>
                        {' '}
                        {listItem}
                      </Text>
                    </div>
                  )
                }) ?? ''}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PageList
