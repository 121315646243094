import React, { AllHTMLAttributes, useCallback, useState } from 'react'
import cn from 'classnames'
import times from 'lodash/times'
import { Button as ReaButton } from 'reakit/Button'
require('./Button.css')
import { Link, Icon } from '@cmp'

type NativeProps = AllHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>

export interface ButtonProps {
  children?: string
  variant?: keyof typeof variants
  size?: keyof typeof sizes
  className?: string
  full?: boolean
  icon?: boolean
  /**
   * animation speed (used to normalize speed)
   */
  pxPerSec?: number
  /**
   * If href is defined the button will be rendered as anchor
   */
  href?: string
  disabled?: NativeProps['disabled']
  onClick?: NativeProps['onClick']
  onFileChange?: (file: File | undefined) => void
  fileInput?: boolean
  hover?: boolean
}

const hovering = {
  primary: 'bg-yellow-300 ',
  secondary: 'bg-violette-500',
  ghost: '',
  ghostInverted: '',
  text: '',
}
const variants = {
  primary: 'bg-yellow-400 text-dark-400 hover:bg-yellow-300',
  secondary: 'bg-violette-400 text-white hover:bg-violette-500',
  ghost: 'hover:text-transparent transition-colors ring-1 ring-dark-400',
  ghostInverted: 'text-white hover:text-transparent transition-colors ring-1 ring-white',
  text: 'text-dark-400',
}
const variantsDisabled = {
  primary: 'bg-transparent ring-1 ring-yellow-400 text-yellow-400',
  // TODO: add disabled styles
  secondary: 'bg-violette-400 text-white hover:bg-violette-500',
  ghost: 'hover:text-transparent text-dark-400 transition-colors ring-1 ring-dark-400',
  ghostInverted: 'text-white hover:text-transparent transition-colors ring-1 ring-white',
  text: 'text-dark-100',
}
const sizes = {
  xs: 'text-btn-sm',
  sm: 'text-btn-sm',
  md: 'text-btn-md',
  lg: 'text-btn-lg',
}

const paddingY = {
  xs: 'py-2',
  sm: 'pt-3 pb-2.5',
  md: 'pt-6 pb-5',
  lg: 'pt-6.5 pb-5',
}

const paddingX = {
  none: 'px-0',
  sm: 'px-6',
  md: 'px-7.5',
  lg: 'px-7.5',
}
const paddings = {
  xs: `${paddingX.none} ${paddingY.xs}`,
  sm: `${paddingX.sm} ${paddingY.sm}`,
  md: `${paddingX.md} ${paddingY.md}`,
  lg: `${paddingX.lg} ${paddingY.lg}`,
}
const color = {
  primary: 'text-dark-400',
  secondary: 'text-white',
  ghost: 'text-dark-400',
  ghostInverted: 'text-white',
  text: 'text-dark-400',
  yellow: 'text-dark-400',
}

export const Button = ({
  children,
  variant = 'primary',
  size = 'md',
  className,
  full,
  icon,
  href,
  pxPerSec = 40,
  disabled,
  onClick,
  onFileChange,
  fileInput,
  hover = true,
}: ButtonProps) => {
  const [buttonWidth, setButtonWidth] = useState(500)
  const [textWidth, setTextWidth] = useState(100)

  const base = cn('leading-none text-left uppercase font-bold relative block overflow-hidden', {
    'hover:text-transparent': hover,
  })
  const variants = {
    primary: 'bg-yellow-400 text-dark-400 hover:bg-yellow-300',
    secondary: 'bg-violette-400 text-white hover:bg-violette-500',
    ghost: cn('text-dark-400 transition-colors ring-1 ring-dark-400', {
      'hover:text-transparent': hover,
    }),
    ghostInverted: 'text-white hover:text-transparent transition-colors ring-1 ring-white',
    yellow: 'bg-yellow-400 hover:bg-yellow-500 text-dark-400',
  }
  const variantsDisabled = {
    primary: 'bg-transparent ring-1 ring-yellow-400 text-yellow-400',
    // TODO: add disabled styles
    secondary: 'bg-violette-400 text-white hover:bg-violette-500',
    ghost: 'hover:text-transparent transition-colors ring-1 ring-dark-400',
    ghostInverted: 'text-white hover:text-transparent transition-colors ring-1 ring-white',
    yellow: cn('bg-yellow-400', {
      'hover:bg-yellow-500': hover,
    }),
  }
  const setHoverContainerRef = useCallback(node => {
    setButtonWidth(node?.clientWidth)
  }, [])

  const setTextRef = useCallback(node => {
    setTextWidth(node?.clientWidth)
  }, [])

  const repeatChild = Math.ceil(buttonWidth / textWidth)
  const animationDuration = buttonWidth / pxPerSec + 's'
  const styles = cn(
    'btn',
    className,
    base,
    disabled ? variantsDisabled[variant] : variants[variant],
    sizes[size],
    paddings[size],
    {
      'w-full': full,
    },
  )

  const hoverContent = () => (
    <div
      ref={setHoverContainerRef}
      className={cn(
        'hoverContainer',
        'overflow-hidden absolute opacity-0 top-0 left-0 right-0 bottom-0 flex whitespace-nowrap transition-opacity duration-200',
      )}
    >
      <div className={cn('hover', hovering[variant], paddingY[size])} style={{ animationDuration }}>
        <div ref={setTextRef} className={cn(color[variant], 'inline-block px-2')}>
          {children}
        </div>
        {times(repeatChild, idx => (
          <div key={idx} className={cn(color[variant], 'inline-block px-2')}>
            {children}
          </div>
        ))}
      </div>
      <div className={cn('hover', hovering[variant], paddingY[size])} style={{ animationDuration }}>
        <div className={cn('text', color[variant], 'inline-block px-2')}>{children}</div>
        {times(repeatChild, idx => (
          <div key={idx} className={cn('text', color[variant], 'inline-block px-2')}>
            {children}
          </div>
        ))}
      </div>
    </div>
  )

  const content = () => (
    <>
      <span className='flex justify-between'>
        {children}
        {icon && <Icon name='CurvedArrow' className='w-3.5' />}
      </span>
      {hover && hoverContent()}
    </>
  )
  if (fileInput) {
    return (
      <label className={styles}>
        {content()}
        {!disabled ? (
          <input
            type='file'
            className='hidden'
            onChange={e => {
              if (e.target.files && e.target.files.length > 0 && onFileChange) {
                onFileChange(e.target.files[0])
              }
            }}
          />
        ) : null}
      </label>
    )
  }

  return href && !disabled ? (
    <ReaButton onClick={onClick} as={Link} to={href} noStyle className={styles}>
      {content()}
    </ReaButton>
  ) : (
    <ReaButton disabled={disabled} onClick={onClick} className={styles}>
      {content()}
    </ReaButton>
  )
}

export default Button
