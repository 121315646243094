import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLanguage } from '@tmp'
import { NavBar } from '@cmp'
import { makePath, Route } from '@src/utils/routes'
import Locale from '@src/utils/locale'
import { objectToPath } from '@src/utils/objectToPath'

const AnnualMenuTemplate = ({
  localizedUrls,
  isAnnualReport = false,
  annualTag,
}: {
  localizedUrls?: Array<{ locale: Locale; route: Route }>
  isAnnualReport?: boolean
  annualTag: string
}) => {
  const data = useStaticQuery(query)

  const annualTagMinified = annualTag.replace('_FRONT', '')

  const filteredData = data?.allContentfulMenu?.nodes?.filter((menu: any) => menu.tag === annualTagMinified) ?? []

  const { locale, t } = useLanguage()
  const menu = filteredData.find((menu: any) => menu.node_locale === locale)

  const languageSwitch = localizedUrls?.filter(u => u.locale !== locale)[0]

  const resolveLink = (menu: any[]): any =>
    menu?.map(nav => {
      if (nav && 'navigation' in nav && nav.navigation && nav.navigation.length > 0) {
        return { title: nav.title, subMenu: resolveLink(nav.navigation) }
      }
      if (nav && 'externalLink' in nav && nav.externalLink) {
        return { title: nav.label, href: nav.externalLink }
      }
      return {
        title: nav.label,
        href: objectToPath(nav),
      }
    })

  const navigation = menu?.navigation ? resolveLink(menu.navigation) : []
  return (
    <NavBar
      searchTitle={t('search')}
      languageTitle={t('switch-language')}
      menuTitle={t('more')}
      closeTitle={t('close')}
      locale={locale}
      navigation={navigation}
      searchPath={makePath(locale, { type: 'search' })}
      languageHref={makePath(languageSwitch?.locale ?? 'is', languageSwitch?.route ?? { type: 'frontpage' })}
      isAnnualReport={isAnnualReport}
      annualTag={annualTag}
    />
  )
}

export default AnnualMenuTemplate

export const query = graphql`
  query GetMainMenus {
    allContentfulMenu {
      nodes {
        __typename
        id
        title
        tag
        node_locale
        navigation {
          ... on ContentfulMenu {
            __typename
            id
            title
            tag
            navigation {
              ...linkFragment
            }
          }
          ... on ContentfulLink {
            ...linkFragment
          }
          ...linkFragment
        }
      }
    }
  }
`
