import { createElement, ElementType, forwardRef, ReactNode } from 'react'
import cn from 'classnames'

export interface TextProps {
  /**
   * Type of element to render.
   */
  as?: ElementType
  /**
   * Type of text.
   */
  variant?: keyof typeof variants
  /**
   * Font weight.
   */
  weight?: keyof typeof weights
  children?: ReactNode
  className?: string
}

export type GetTextStylesProps = Pick<TextProps, 'variant' | 'weight'>

const variants = {
  p: 'text-p',
  pAlt: 'text-p-alt',
  largeHeader: 'text-h2-m lg:text-large-header font-heavy',
  h1: 'text-h1-m lg:text-h1 font-heavy',
  h1Alt: 'text-h1-m lg:text-h1-alt font-bold',
  h1Alt2: 'text-h1-m lg:text-h1-alt2 font-bold',
  h2: 'text-h2-m lg:text-h2 font-bold',
  h3: 'text-h3-m lg:text-h3 font-bold',
  keynumbers: 'text-h2 lg:text-h1 font-bold',
  h3Alt: 'text-h3-m lg:text-h3-alt font-heavy',
  h4: 'text-h4 font-bold',
  medium: 'text-medium-m lg:text-medium',
  intro: 'text-intro-m lg:text-intro',
  sm: 'text-sm',
  xs: 'text-xs',
  table: 'text-sm lg:text-table',
  base: 'text-base',
}

const weights = {
  light: 'font-light',
  normal: 'font-normal',
  bold: 'font-bold',
  heavy: 'font-heavy',
}

export const getTextStyles = ({ variant = 'p', weight }: GetTextStylesProps, className: string = '') =>
  cn('antialiased', variants[variant], className, {
    [weights[weight!]]: weight,
  })

export const Text = forwardRef<HTMLElement, TextProps>(
  ({ as = 'p', className, variant, weight, ...restProps }, ref) => {
    return createElement(as, {
      className: getTextStyles({ variant, weight }, className),
      ...restProps,
      ref,
    })
  },
)

export default Text
