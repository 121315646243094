import { ChartOptions } from 'chart.js'
import { useEffect } from 'react'

export const chartColors = ['#F5D046', '#EC742F', '#EA3931', '#B60015', '#73000D', '#FFECA8', '#580075', '#DFCCE6']
export const oldChartColors = [
  '#E27550',
  '#E9CC5B',
  '#93A3B5',
  '#86A7E9',
  '#A64FA1',
  '#3F6ECF',
  '#7A391F',
  '#EFE1A9',
]

export function splitStringIntoSubarrays(str: string, maxLength: number): string[] {
  const words = str.split(' ')
  const result: string[] = []
  let currentSubstring = ''

  for (const word of words) {
    if (currentSubstring.length === 0) {
      currentSubstring = word
    } else {
      const combinedLength = currentSubstring.length + word.length + 1 // 1 for the space
      if (combinedLength <= maxLength) {
        currentSubstring += ` ${word}`
      } else {
        result.push(currentSubstring)
        currentSubstring = word
      }
    }
  }

  if (currentSubstring.length > 0) {
    result.push(currentSubstring)
  }

  return result
}

export const SMALL_FONT = 15
export const LARGE_FONT = 18

export const chartOptions: ChartOptions = {
  plugins: {
    deferred: {
      xOffset: 150,
      yOffset: '50%',
      delay: 500,
    },
    datalabels: {
      display: true,
      color: '#160D13',
      formatter: Math.round,
      font: {
        size: LARGE_FONT,
        family: 'DIN Next',
        weight: 'lighter',
      },
      anchor: 'end',
      offset: -30,
      align: 'start',
    },
    legend: {
      position: 'bottom',
      align: 'center',
      title: { display: true, padding: 8 }, // hack to make space between bottom legend and chart
      labels: {
        padding: 20,
        boxHeight: 7,
        boxWidth: 21,
        font: {
          size: LARGE_FONT,
          family: 'DIN Next',
          weight: 'lighter',
        },
      },
    },
  },
  font: {
    size: LARGE_FONT,
    family: 'DIN Next',
    weight: 'lighter',
  },
  maintainAspectRatio: false,
  aspectRatio: 3 / 1,
  responsive: true,
  events: [],
  borderColor: 'transparent',
  scales: {
    x: {
      border: {
        color: '#160D13',
      },
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: LARGE_FONT,
          family: 'DIN Next',
          weight: 'lighter',
        },
        padding: 10,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: true,
      },
      angleLines: {
        display: false,
        lineWidth: 0,
        color: 'transparent',
      },
      ticks: {
        font: {
          size: LARGE_FONT,
          family: 'DIN Next',
          weight: 'lighter',
        },
        padding: 10,
      },
    },
  },
}

export const useChartResize = ({
  ChartJS,
  id,
  renderSmall,
  renderLarge,
}: {
  ChartJS: any
  id: string
  renderSmall?: () => void
  renderLarge?: () => void
}) => {
  const chart = ChartJS.getChart(id ?? '')

  useEffect(() => {
    const onResize = () => {
      if (chart && typeof window === 'object') {
        if (window.innerWidth < 768) {
          if (chart.options.plugins?.datalabels?.font) {
            chart.options.plugins.datalabels.font = {
              size: SMALL_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
          }
          if (chart.options.scales?.x?.ticks?.font && chart.options.scales?.y?.ticks?.font) {
            chart.options.scales.x.ticks.font = {
              size: SMALL_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
            chart.options.scales.y.ticks.font = {
              size: SMALL_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
          }
          if (chart.options.font) {
            chart.options.font.size = SMALL_FONT
          }
          if (chart?.legend?.options.labels.font) {
            chart.legend.options.align = 'start'
            chart.legend.options.labels.font = {
              size: SMALL_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
          }
          renderSmall?.()
        } else {
          if (chart.options.plugins?.datalabels?.font) {
            chart.options.plugins.datalabels.font = {
              size: LARGE_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
          }
          if (chart.options.scales?.x?.ticks?.font && chart.options.scales?.y?.ticks?.font) {
            chart.options.scales.x.ticks.font = {
              size: LARGE_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
            chart.options.scales.y.ticks.font = {
              size: LARGE_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
          }
          if (chart.options.font) {
            chart.options.font.size = LARGE_FONT
          }
          if (chart?.legend?.options.labels.font) {
            chart.legend.options.align = 'center'
            chart.legend.options.labels.font = {
              size: LARGE_FONT,
              family: 'DIN Next',
              weight: 'lighter',
            }
          }
          renderLarge?.()
        }
      }
    }

    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [chart])
}
