import React, { useEffect, useContext, useState, forwardRef } from 'react'
import cn from 'classnames'
import { CarouselContext, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import { Card, CardProps, Icon } from '@cmp'

export interface CarouselProps {
  cards: CardProps[]
  isSlideable: boolean
  visibleSlideCount: number
  className?: string
}

const Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  ({ cards, isSlideable, visibleSlideCount, className = '' }, ref) => {
    const carouselContext = useContext(CarouselContext)
    const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)

    useEffect(() => {
      const onChange = () => {
        setCurrentSlide(carouselContext.state.currentSlide)
      }
      carouselContext.subscribe(onChange)
      return () => carouselContext.unsubscribe(onChange)
    }, [carouselContext])

    return (
      <div ref={ref} className={cn('relative m-auto overflow-hidden group', className)}>
        <ButtonBack
          className={cn(
            'z-10 absolute left-0 transform focus:outline-none w-1/6 h-full bg-gradient-radial invisible',
            {
              'md:visible': currentSlide !== 0,
            },
          )}
        >
          <Icon name='GradientArrow' className='relative transform opacity-70 left-5' />
        </ButtonBack>

        <ButtonNext
          className={cn(
            'z-10 absolute right-0 transform rotate-180 focus:outline-none w-1/6 h-full bg-gradient-radial invisible',
            {
              'md:visible':
                Math.ceil(currentSlide) < carouselContext.state.totalSlides - visibleSlideCount && isSlideable,
            },
          )}
        >
          <Icon name='GradientArrow' className='relative transform opacity-70 left-5' />
        </ButtonNext>
        <Slider
          classNameTray='flex px-4 gutter-l'
          classNameAnimation='transition-transform ease-in-out duration-500'
          moveThreshold={0.2}
        >
          {cards.map((card, idx) => (
            <Slide className='px-2' key={idx} index={idx}>
              <Card {...card} variant={idx % 2 ? 1 : 0} />
            </Slide>
          ))}
        </Slider>
      </div>
    )
  },
)

export default Carousel
