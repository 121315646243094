import React from 'react'
import RichText from '../RichText'
import cn from 'classnames'
import Collapse from '../Collapse'
import Text from '../Text'

type QandAItem = {
  id: string
  question: string
  answer: { raw: string }
}

export interface QandAProps {
  className: string
  title: string | undefined | null
  items?: QandAItem[] | undefined | null
}

export const QandA = ({ title, items, className }: QandAProps) => {
  return (
    <div className={className}>
      {title && (
        <Text as='h3' variant='h3' weight='heavy' className='pb-8'>
          {title}
        </Text>
      )}

      {items?.map((item, idx) => {
        const isFirst = idx === 0
        return (
          <div key={idx} className={cn('border border-dark-400', { 'border-t-0': !isFirst })}>
            <Collapse className='px-6 py-4' title={item.question}>
              <RichText
                className='px-6 py-4'
                document={{ raw: item.answer.raw }}
                textStyles={{ weight: 'light' }}
              />
            </Collapse>
          </div>
        )
      })}
    </div>
  )
}
export default QandA
