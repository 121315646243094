import Locale from './locale'
import { stringify } from 'qs'

export type Route =
  | { type: 'frontpage' }
  | { type: 'dagskra' }
  | { type: 'fundarherbergi' }
  | { type: 'annual'; slug: string }
  | { type: 'event'; slug: string }
  | { type: 'search'; term?: string }
  | { type: 'newsList' }
  | { type: 'newsItem'; slug: string }
  | { type: 'venue'; slug: string; parentSlug: string | null | undefined }
  | { type: 'page'; slug: string; parentSlug: string | null | undefined }
  | { type: 'staff' }
  | { type: 'success'}
  | { type: 'error'}

export interface LocalizedRoute {
  locale: Locale
  route: Route
}

export const isDefined = <T>(x?: T | null): x is T => x != null

const path = (paths: string[], query?: Record<string, string | undefined>): string => {
  const qs = stringify(query)
  return '/' + paths.join('/') + (qs && '?' + qs)
}

const makePathIS = (r: Route): string => {
  switch (r.type) {
    case 'frontpage':
      return path([])
    case 'dagskra':
      return path(['dagskra'])
    case 'fundarherbergi':
      return path(['fundarherbergi'])
    case 'annual':
      return path([`arsskyrsla-${r.slug}`])
    case 'event':
      return path([r.slug])
    case 'search':
      return path(['leitarnidurstodur'], { term: r.term })
    case 'newsList':
      return path(['frettir'])
    case 'newsItem':
      return path(['frettir', r.slug])
    case 'venue':
    case 'page':
      return path([r.parentSlug, r.slug].filter(isDefined))
    case 'staff':
      return path(['starfsfolk'])
    case 'success':
      return path(['takk-fyrir'])
    case 'error':
      return path(['villa'])
  }
}

const makePathEN = (r: Route): string => {
  switch (r.type) {
    case 'frontpage':
      return path([])
    case 'dagskra':
      return path(['whats-on'])
    case 'fundarherbergi':
      return path(['meeting-room'])
    case 'annual':
      return path([`annual-and-sustainability-report-${r.slug}`])
    case 'event':
      return path([r.slug])
    case 'search':
      return path(['searchresults'], { term: r.term })
    case 'newsList':
      return path(['news'])
    case 'newsItem':
      return path(['news', r.slug])
    case 'venue':
    case 'page':
      return path([r.parentSlug, r.slug].filter(isDefined))
    case 'staff':
      return path(['staff'])
    case 'success':
      return path(['success'])
    case 'error':
      return path(['error'])
  }
}

const localizers: Record<Locale, (route: Route) => string> = {
  is: makePathIS,
  en: route => '/en' + makePathEN(route),
}

export const makePath = (locale: Locale, route: Route): string => {
  if (!localizers[locale]) {
    throw new Error(`Unknown locale: ${locale}`)
  }
  return localizers[locale](route)
}
