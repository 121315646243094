import React from 'react'
import cn from 'classnames'
import { Hyphen, Text } from '@cmp'

export interface InfoTableProps {
  className?: string
  title: string | null | undefined
  tableData?: string[][] | null
  tableBackgroundColor?: string
}

export const InfoTable = ({
  title,
  tableData,
  className = '',
  tableBackgroundColor = 'green',
}: InfoTableProps) => {
  let color = 'transparent'

  switch (tableBackgroundColor) {
    case 'blue':
      color = 'rgba(0, 97, 98, 0.08)'
      break
    case 'green':
      color = 'rgba(245, 208, 70, 0.08)'
      break
    case 'red':
      color = 'rgba(182, 0, 21, 0.08)'
      break
    default:
      break
  }

  return (
    <div className={className}>
      {tableData?.length && (
        <div className='mb-20'>
          <div className='gutter'>
            <Text variant='h3' weight='heavy' className='mb-8'>
              {title}
            </Text>
          </div>
          <div className='px-4 md:px-10 max-w-[100vw] overflow-scroll no-scrollbar'>
            <div className='w-full min-w-[900px] table border border-collapse'>
              {tableData?.map((info: string[], mainIndex: number) => {
                return (
                  <div
                    className={cn('border-dark-300 table-row border', {
                      'border-b': mainIndex === tableData.length - 1,
                    })}
                    style={{ background: color }}
                    key={mainIndex}
                  >
                    {info.length > 0 ? (
                      info.map((item: string, index: number) => {
                        const isTopRow = mainIndex === 0
                        const isLast = index === info.length - 1
                        const isFirstInRow = index === 0

                        return (
                          <div
                            key={index}
                            className={cn('w-3/12 px-6 md:px-6 pt-3 pb-2 md:pt-4 md:pb-3 table-cell border', {
                              'border-r': !isLast,
                              'flex flex-col justify-end': isTopRow,
                            })}
                            style={{
                              minWidth: isFirstInRow ? 320 : 'auto',
                            }}
                          >
                            <Text weight={isFirstInRow ? 'normal' : 'light'} variant='sm'>
                              <Hyphen>{item ?? '-'}</Hyphen>
                            </Text>
                          </div>
                        )
                      })
                    ) : (
                      <Text>-</Text>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default InfoTable
