import React from 'react'
import Icons from '@img'

export interface IconProps {
  /**
   * Unique identifier for icons currently in storage.
   */
  name: IconType
  className?: string

  /**
   * The color of the icon
   */
  color?: string
  /**
   * Optional props that can be passed to the SVG html tag.
   */
}

export type IconType =
  | 'HarpaHorizontal'
  | 'HarpaSingle'
  | 'HarpaVertical'
  | 'Arrow'
  | 'CurvedArrow'
  | 'Search'
  | 'Swirl'
  | 'GradientArrow'
  | 'Chevron'
  | 'Union'
  | 'Plus'
  | 'Minus'
  | 'CheckMark'
  | 'CheckMarkBlack'
  | 'PlusWhite'
  | 'MinusWhite'
  | 'UnionWhite'

export const Icon = ({ name, className = '', color = 'currentColor' }: IconProps) => {
  const SVGIcon = Icons[name]
  return <SVGIcon className={className} color={color} />
}

export default Icon
