import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { footerMapping } from '@data'
import { useLanguage } from '@tmp'
import { useCookieBanner } from '@src/hooks/useCookieBanner'

const FooterTemplate = ({ className, footerPoly }: { className?: string; footerPoly?: boolean }) => {
  const data = useStaticQuery(query)
  const { locale } = useLanguage()
  const { openSettings } = useCookieBanner()

  const footer = data[locale]

  if (footer) {
    var mapType = footerMapping[footer.__typename]
    var FooterContent = mapType.component()
    var footerContentProps = mapType.dataToProps(footer)
    return (
      <FooterContent
        {...footerContentProps}
        openCookieSettings={openSettings}
        className={className}
        footerPoly={footerPoly}
      />
    )
  }

  return null
}

export default FooterTemplate

export const query = graphql`
  query mainFooterQuery {
    is: contentfulFooter(tag: { eq: "MAIN_FOOTER" }, node_locale: { eq: "is" }) {
      ...footerFragment
    }
    en: contentfulFooter(tag: { eq: "MAIN_FOOTER" }, node_locale: { eq: "en" }) {
      ...footerFragment
    }
  }
`
