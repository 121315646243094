import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLanguage } from '@tmp'
import { NavBar } from '@cmp'
import { makePath, Route } from '@src/utils/routes'
import Locale from '@src/utils/locale'
import { GetMainMenuQuery } from '@gql-types'
import { objectToPath } from '@src/utils/objectToPath'

const MenuTemplate = ({ localizedUrls }: { localizedUrls?: Array<{ locale: Locale; route: Route }> }) => {
  const data = useStaticQuery<GetMainMenuQuery>(query)

  const { locale, t } = useLanguage()
  const menu = data[locale]

  const languageSwitch = localizedUrls?.filter(u => u.locale !== locale)[0]

  const resolveLink = (menu: any[]): any =>
    menu?.map(nav => {
      if (nav && 'navigation' in nav && nav.navigation && nav.navigation.length > 0) {
        return { title: nav.title, subMenu: resolveLink(nav.navigation) }
      }
      if (nav && 'externalLink' in nav && nav.externalLink) {
        return { title: nav.label, href: nav.externalLink }
      }
      return {
        title: nav.label,
        href: objectToPath(nav),
      }
    })

  const navigation = menu?.navigation ? resolveLink(menu.navigation) : []
  return (
    <NavBar
      searchTitle={t('search')}
      languageTitle={t('switch-language')}
      menuTitle={t('more')}
      closeTitle={t('close')}
      locale={locale}
      navigation={navigation}
      searchPath={makePath(locale, { type: 'search' })}
      languageHref={makePath(languageSwitch?.locale ?? 'is', languageSwitch?.route ?? { type: 'frontpage' })}
    />
  )
}

export default MenuTemplate

export const query = graphql`
  query GetMainMenu {
    is: contentfulMenu(tag: { eq: "MAIN_MENU" }, node_locale: { eq: "is" }) {
      ...menuFragment
    }
    en: contentfulMenu(tag: { eq: "MAIN_MENU" }, node_locale: { eq: "en" }) {
      ...menuFragment
    }
  }
`
