export const ALLOW_EMBEDDED_THIRD_PARTY = 'allow_embedded_third_party'
export const MEETING_MAX = 20

// input name/ids
export const QUESTION_LIST_PREFIX = 'questionList'
export const EVENT_NAME = 'eventInfo.event_name'
export const SPECIAL_WISH_DETAILS = 'eventInfo.special_wish_details'
export const GRAND_PIANO = 'eventInfo.grand_piano'
export const ANNUAL_REPORT_2022 = 'ANNUAL_REPORT_2022'
export const ANNUAL_REPORT_2023 = 'ANNUAL_REPORT_2023'
export const ANNUAL_REPORT_FRONT_2022 = 'ANNUAL_REPORT_FRONT_2022'
export const ANNUAL_REPORT_FRONT_2023 = 'ANNUAL_REPORT_FRONT_2023'
export const SOLD_OUT_STATUS = 2


export const MEDIA_PAGE_IS = 'fjolmidlatorg'
export const MEDIA_PAGE_EN = 'media-center'

const pink100 = '#F8E2FF#'
const darkGreen200 = '#88B8B9'
const purple200 = '#9C9AE3'
const blue500 = '#0046B3'
const lightGreen600 = '#005C46'

const base_1 = '#E27550'
const base_2 = '#E9CC5B'
const base_3 = '#93A3B5'
const base_4 = '#86A7E9'
const base_5 = '#A64FA1'
const base_6 = '#3F6ECF'

const secondary_1 = '#7A391F'
const secondary_2 = '#EFE1A9'
const secondary_3 = '#404A59'
const secondary_4 = '#1D3A7C'
const secondary_5 = '#DBBDDB'
const secondary_6 = '#8AA1DB'

export const baseColors = [base_1, base_2, base_3, base_4, base_5, base_6]

export const extraColors = [secondary_1, secondary_2, secondary_3, secondary_4, secondary_5, secondary_6]

export const extraExtraColors = [pink100, darkGreen200, purple200, lightGreen600, blue500]

export const chartColors = [...baseColors, ...extraColors, ...extraExtraColors]
