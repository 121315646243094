import { NavBar, NavBarProps } from '@cmp'
import { MenuFragmentFragment } from '@gql-types'
import { objectToPath } from '@src/utils/objectToPath'
import { isDefined } from '@utils/helpers'

interface MenuMapping {
  [key: string]: {
    component: () => React.FunctionComponent<NavBarProps>
    // @ts-expect-error
    dataToProps: (data: MenuFragmentFragment) => Pick<NavBarProps, 'navItems'>
  }
}
// @ts-expect-error
const makeNavLinks = (navigation: MenuFragmentFragment['navigation']): NavBarProps['navItems'] => {
  // @ts-expect-error
  return (navigation ?? []).filter(isDefined).map((nav): NavBarProps['navItems'][0] => {
    switch (nav.__typename) {
      case 'ContentfulMenu':
        return {
          title: nav.title ?? '',
          submenu: makeNavLinks(nav.navigation),
        }
      case 'ContentfulLink':
        return {
          title: nav.title ?? '',
          button: {
            link: objectToPath(nav),
            label: nav.label ?? '',
          },
        }
    }
  })
}

const menuMapping: MenuMapping = {
  ContentfulMenu: {
    component: () => NavBar,
    dataToProps: (data: MenuFragmentFragment) => ({
      navItems: makeNavLinks(data.navigation),
    }),
  },
}

export default menuMapping
