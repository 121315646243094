import { useState, useEffect } from 'react'

interface Windowsize {
  width: number | undefined
  height: number | undefined
}

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<Windowsize>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { width: windowSize.width, height: windowSize.height }
}

export { useWindowSize }
